<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h1>User checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="createUser">Create User</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="listUsers">List Users</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deleteUser">Delete User</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="updateUser">Update User</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>Tranlog checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="createTranlog">Add Tranlog</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getTranlog">Get Tranlog</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="updateTranlog">Update Tranlog</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deleteTranlog">Delete Tranlog</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>Contact checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="addContact">Add Contact</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getContacts">Get Contacts</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="updateContact">Update Contact</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getContactType">Get Contact Types</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deleteContact">Delete Contact</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>People Checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="getPeopleForFirm">Get People for Firm</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="listPeople">List People</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deletePerson">Delete Person</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="createPerson">Create Person</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="updatePerson">Update Person</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>Firm Checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="updateFirm">Update Firm</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getFirmPeople">Get Firm People</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getFirmMembers">Get Firm Members</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getFirmNotes">Get Firm Notes</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getFirmClaimTotals">Get Firm Claim Totals</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getFirmFiles">Get Firm Files</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getFirmForms">Get Firm Forms</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>Note Checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="createNote">Create Note</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deleteNote">Delete Note</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="updateNote">Update Note</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="getNextNote">Get Next Note</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="searchNotes">Search Notes</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>File Objects</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="listFileObjects">Get File Objects</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="createFileObject">Create File Objects</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="updateFileObject">Update File Object</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deleteFileObject">Delete File Object</button>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h1>Recipient Checks</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <button class="btn btn-primary" @click="addRecipient">Test recipient add</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="deleteRecipient">Delete recipient</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="listRecipient">List recipient</button>
        </v-col>
        <v-col>
          <button class="btn btn-primary" @click="sendEmail">Test Emailer</button>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1>File REL Fid checks</h1>
        </v-col>
        <v-row>
          <v-col>
            <button class="btn btn-primary" @click="createFileRelFid">Create File Rel FID</button>
          </v-col>
        </v-row>
      </v-row>
      <v-row>


        <v-col>
          <h1>File Uploading</h1>
        </v-col>
        <v-row>
          <v-col>
            <span>Upload Dominion file:</span>

            <input type="file" ref="domfiles" name="domfiles" multiple/> <br>
            <button type="button" @click='uploadFile()'>Upload file</button>
          </v-col>
        </v-row>

      </v-row>
      <v-row>
        <v-col>
          <h1>File Uploading with fid</h1>
        </v-col>
        <v-row>
          <v-col>
            <span>Upload Dominion file with fid:</span>

            <input type="file" ref="domfiles" name="domfiles" multiple/> <br>
            <button class="btn btn-primary" type="button" @click='uploadFileToFirm()'>Upload file </button>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { CommonAPIService } from '@/servicehandlers/CommonHandler';
import { EmailAPIService } from '@/servicehandlers/EmailAPIService';
import { RecipientAPIService } from '@/servicehandlers/RecipientAPIService';
import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
import { ContactAPIService } from '@/servicehandlers/ContactAPIService';
import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
import { PeopleAPIService } from "../servicehandlers/PeopleAPIService";
import { UsersAPIService } from "../servicehandlers/UsersAPIService";
import { FileobjectsAPIService } from "../servicehandlers/FileobjectsAPIService";
import { TranlogAPIService } from "../servicehandlers/TranlogAPIService";
import loginCheckMixin from '@/mixin';

const apiService = new CommonAPIService();
const emailer = new EmailAPIService();
const recipient = new RecipientAPIService();
const note = new NotesAPIService();
const contact = new ContactAPIService();
const firms = new FirmsAPIService();
const people = new PeopleAPIService();
const user = new UsersAPIService();
const file_objects = new FileobjectsAPIService();
const tranlog = new TranlogAPIService();
export default {
  name: "NateTester",
  mixins: [loginCheckMixin],
  created() {
    document.title = 'Nate Tester';
    this.rightsArray = this.loginCheckMixin(['claim-view', 'firm-view']);
    if (!this.rightsArray.includes('claim-view') || !this.rightsArray.includes('firm-view')) {
      localStorage.removeItem('jwtToken');
      this.$store.dispatch('LOGOUT');
      this.$router.push({name: 'Login',});
    }
  },
  methods: {
    createFileRelFid: function (){
      console.debug('createFileRelFid does not do anything currently');
    },

    uploadFile: function () {
      this.files = this.$refs.domfiles.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.domfiles.files) {
        formData.append('domfiles', fle);
      }
      file_objects.uploadFile(formData, this.$router)
        .then((response) => {
        if (!response.data) {
          console.log('Error uploading files');
        } else {
          console.log('Response data: ', response.data);
          console.log('File uploaded successfully.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    uploadFileToFirm: function () {
      this.files = this.$refs.domfiles.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.domfiles.files) {
        formData.append('domfiles', fle);
      }
      file_objects.uploadFileToFirm(formData, '22222', this.$router)
                  .then((response) => {
                    if (!response.data) {
                      console.log('Error uploading files');
                    } else {
                      console.log('Response data: ', response.data);
                      console.log('File uploaded successfully.');
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
    },

    deleteTranlog() {
      tranlog.deleteTranlog(947701, this.$route)
      .then((response) => {
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    getTranlog() {
      tranlog.getTranlog(10000, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    updateTranlog() {
      const parms = {
        status: 'UPDATED TEST',
        note: 'UPDATED NOTE',
      };

      tranlog.updateTranlog(947702, parms, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    createTranlog() {
      const now = moment().format('YYYY-MM-DD');
      const parms = {
        nid: 10000,
        type: 'email',
        queued: now,
        sent: now,
        orig: true,
        fail: false,
        status: 'test',
        note: 'test',
      };

      tranlog.createTranlog(parms, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    deleteFileObject() {
      file_objects.deleteFileObject(123456790, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    listFileObjects() {
      file_objects.listFileObject(123456790, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    updateFileObject() {
      const my_file_object = {
        filename: 'mychanged name.pdf',
        content_type: 'pdf',
        file_description: 'my new file description',
      };
      file_objects.updateFileObject(123456790, my_file_object, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    createFileObject() {
      const now = moment().format('YYYY-MM-DD');
      const my_file_object = {
        loid: 123456790,
        filename: 'test.pdf',
        content_type: 'pdf',
        file_description: 'file_desc',
        file_type: 4,
        dated: now,
        size: 42,
        proc: false,
        actual_loid: 123456790,
      };
      file_objects.createFileObject(my_file_object, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    deleteContact() {
      contact.deleteContact(100, this.$router)
      .then((mycontact) => {
        console.log(mycontact);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    getContacts() {
      contact.getContacts(this.$router)
      .then((contacts) => {
        console.log(contacts);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    addContact() {
      const now = moment().format('YYYY-MM-DD');
      const mycontact = {
        stamp: now,
        fein: '12345',
        password: 'foo',
        type: 'Producer',
        sort: 'New',
        name1: 'TEST USER',
        name2: 'LAST NAME',
        address1: 'My Address',
        address2: 'My Address 2',
        city: 'UT',
        state: 'Alpine',
        zip: '84119',
        phone: '8012222222',
        email: 'test@foo.com',
        note: 'My special note',
      };
      contact.createContact(mycontact, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    listUsers() {
      user.getUsers(this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    updateUser() {
      // const person = {
      //   username: 'test1',
      //   password: 'test1',
      //   multiple_logins: false,
      //   sysuser: 'test1',
      //   enabled: true,
      //   imusername: 'test1',
      //   company: 'foo',
      //   payroll: 'test',
      //   plid: 4,
      // };

      const person = {
        username: 'nate',
        password: 'test1',
        multiple_logins: false,
        sysuser: 'test1',
        enabled: true,
        imusername: 'nate@dominioninsurance.com',
        company: 'foo',
        payroll: 'test',
        timeout: {minutes: 55},
        plid: 4,
      };
      // AuthGroups: [{id: 1, name: "admin", AuthGroupsUsers: {AuthGroupId: 1, userId: 344},…}]
      // company: null
      // enabled: true
      // id: 344
      // imusername: "nate@dominioninsurance.com"
      // multiple_logins: false
      // payroll: null
      // plid: null
      // shared: false
      // sysuser: null
      // timeout: {hours: 5}
      // username: "nate"


      // user.updateUser(14, person, this.$route)
      user.updateUser(344, person, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    deleteUser() {
      user.deleteUser(238, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    createUser() {
      const person = {
        username: 'blahness87654',
        password: 'blahness2',
        multiple_logins: false,
        sysuser: 'blahness2',
        enabled: true,
        imusername: 'blahness2',
        company: 'foo',
        payroll: 'test',
        plid: 4,
      };
      user.createUser(person, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    getPeopleForFirm() {
      people.getPeopleForFirm(1, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });

    },

    listPeople() {
      people.getSearchPeople('', this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    createPerson() {
      const now = moment().format('YYYY-MM-DD');
      const mycontact = {
        stamp: now,
        fid: 10,
        password: 'foo',
        surname: 'TOASTER',
        given: 'GIVN NAME',
        salutation: 'Mr.',
        address: '1222 E 1222 W',
        city: 'Alpine',
        state: 'UT',
        zip: '84114',
        plid: 100,
        mobile: '8012222222',
        cid: 100,
        userid: 100,
        note: 'my note',
      };
      people.createPerson(mycontact, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    deletePerson() {
      people.deletePerson(10, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    updatePerson() {
      const peopleItems = {
        fid: 4,
        surname: 'foo',
        state: 'AZ',
        zip: 'name2test',
        email: 'test@foo.com',
      };
      //firmItems can be whole firm, like this.firm, can see fields in models
      //might have to delete id
      people.updatePerson(4, peopleItems, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    updateContact() {
      const contactItems = {
        fein: '123456',
        password: 'foo',
        name1: 'name1 est',
        name2: 'name2test',
        address1: 'fdsfdas est',
        address2: 'name2test',
      };
      //firmItems can be whole firm, like this.firm, can see fields in models
      //might have to delete id
      contact.updateContact(4, contactItems, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    getFirmMembers() {
      firms.getFirmMembers(3, this.$router)
      .then((people) => {
        console.log(people);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    getFirmPeople() {
      firms.getFirmPeople(3, this.$router)
      .then((people) => {
        console.log(people);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    getFirmClaimTotals() {
      firms.getFirmClaimTotals(5, this.$router)
      .then((notes) => {
        console.log(notes);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    getFirmForms() {
      firms.getFirmForms(5, this.$router)
      .then((notes) => {
        console.log(notes);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    getFirmFiles() {
      const parms = {
        page: 1,
        perPage: 100,
      };
      firms.getFirmFiles(1, parms, this.$router)
      .then((notes) => {
        console.log(notes);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    getFirmNotes() {
      const parms = {
        page: 1,
        perPage: 100,
      };
      firms.getFirmNotes(1, 1, 100, this.$router)
      .then((notes) => {
        console.log(notes);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    updateFirm() {
      const firmItems = {
        source: 'tester',
        password: 'foo',
        name1: 'name1 est',
        name2: 'name2test',
      };
      //firmItems can be whole firm, like this.firm, can see fields in models
      //might have to delete id
      firms.updateFirm(77, firmItems, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    updateNote() {
      // the note items can be any field in the DB.  You can see all the items it can update
      // in the models/notes.js file.
      const noteItems = {
        status: 'NAte did this',
        opener: 'Larry',
        re: 'Nate did this to the note',
      };
      note.updateNote(10001, noteItems, this.$router)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    getNextNote() {
      const id = 1;
      note.getNextNote(id, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    searchNotes() {
      const searchItems = {
        page: 1,
        perPage: 50,
        whereObjects: {
          note: 'test',
          nid: 1,
        },
      };
      note.searchNotes(searchItems, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    getContactType() {
      const mytype = 'Adjuster';
      contact.getByType(mytype, this.$route)
      .then((response) => {
        console.log('response: ', response);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    },

    sendEmail() {
      const nid = 1000;
      const loids = [];
      loids.push(436868480);
      loids.push(437081179);
      const parms = {
        subject: 'SUBJECT OF MESSAGE',
        body: 'BODY OF MESSAGE',
        username: 'larry',
        attach: true,
        loids,
      };
      emailer.sendEmailToNote(nid, parms, this.$route)
      .then((response) => {
        console.log('Response: ', response);
      })
      .catch((error) => {
        console.log('EMAIL SEND ERROR');
      })
    },

    // loginCheck() {
    //   let loggedIn = this.$store.getters.loggedIn;
    //   this.loggedIn = loggedIn;
    //   if (!loggedIn) {
    //     if (this.$cookies.isKey('sessid')) {
    //       apiService.cookieLogin(this.$router, this.$store, this.errors)
    //       .then(() => {
    //         loggedIn = this.$store.getters.loggedIn;
    //         this.loggedIn = loggedIn;
    //         if (!loggedIn) {
    //           this.$router.push({
    //             name: 'Login',
    //           });
    //         } else {
    //           this.rightsCheck();
    //         }
    //       })
    //       .catch((error) => {
    //         this.errors.push(error);
    //       });
    //     } else {
    //       this.$router.push({
    //         name: 'Login',
    //       });
    //     }
    //   } else {
    //     this.rightsCheck();
    //   }
    // },
    //
    // rightsCheck() {
    //   // do I have rights to view this page or edit this page?
    //   let seePage = false;
    //   const rights = this.$store.getters.scopes;
    //   seePage = true;
    //   console.log('seePage', seePage);
    //   if (!seePage) {
    //     localStorage.removeItem('jwtToken');
    //     this.$store.dispatch('LOGOUT');
    //     this.$router.push({
    //       name: 'Login',
    //     });
    //   }
    // },

    deleteNote() {
      note.deleteNote(10000, this.$route)
      .then((note) => {
        console.log('note: ', note);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
    },

    createNote() {
      const parms = {
        fid: 1,
        plid: 1,
        opener: 'nate',
        closer: 'nate',
        signer: 'nate',
        type: 'eml',
        re: 'This is the re of the note',
        note: 'This is the note of the note',
      };
      return note.createNote(parms, this.$route);
    },

    deleteRecipient() {
      return recipient.destroy(1000, this.$route);
    },

    listRecipient() {
      return recipient.list(1000, this.$route);
    },

    addRecipient() {
      const nid = 1000;
      const parms = [
        {
          plid: 4,
          nid: 1000,
          to: true,
        }, {
          plid: 5,
          nid: 1000,
          cc: true,
        }, {
          plid: 6,
          nid: 1000,
          bcc: true,
        }
      ];
      return recipient.create(nid, parms, this.$route)
      .then((data) => {
        console.log('DATA: ', data);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      })
    },
  }
}
</script>

<style scoped>

</style>
