import { render, staticRenderFns } from "./PeopleSearch.vue?vue&type=template&id=69f6d5ce&scoped=true"
import script from "./PeopleSearch.vue?vue&type=script&lang=js"
export * from "./PeopleSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69f6d5ce",
  null
  
)

export default component.exports