<template>
  <div class="pb-0 mb-0" v-if="!readonlyType">
    <v-skeleton-loader v-if="loading" type="list-item-two-line"></v-skeleton-loader>
    <draggable class="pt-3 ml-5 mr-6 mb-n6" v-model="filesArray" :disabled="readonlyType || notUser"
               style="min-height: 36px">
      <div v-for="file in filesArray" :key="file.loid">
        <!-- don't show admin files unless user is admin -->
        <v-row class="d-flex flex-row mt-n1 mb-n5" style="padding-left: 1vw; padding-right: 1vw"
               v-if="groups.includes('admin') || !(file.file_type === 1 || file.f_type === 'Admin')">
          <div v-if="!readonlyType && !notUser">
            <button-tip icon iname="mdi-close" tip="Delete from note files/attachments"
                        istyle="margin-top: 2px; color: #B22222" @click="disconnectFile(file)" :disabled="notUser"
                        xsmall/>
          </div>
          <button-tip btnclass="mt-1 mx-2" :disabled="notUser" icolor="accent1" icon iname="mdi-menu"
                      tip="Drag" xsmall/>
          <button-tip btnclass="mt-1" xsmall a :href="`${url}/download/${file.loidfid}`"
                      icon iname="mdi-download" tip="Download" istyle="color: blue"/>
          <span style="color: black; cursor: pointer; font-size: .85rem; margin-top: 3px; max-width: 65px;
            min-width: 65px; text-align: right">{{$_prettyBytes(file.size)}}</span>
          <v-text-field
              :disabled="readonlyType || notUser ||
                (!groups.includes('admin') && (file.file_type === 3 || file.f_type === 'Form'))"
              style="margin-top: -2px; margin-bottom: 9px; font-size: 14px"
              v-model="file.file_description" class="file pl-2" dense
              @input="!filesChanged.includes(file.loid) ? filesChanged.push(file.loid) : ''"
          ></v-text-field>
        </v-row>
      </div>
    </draggable>
    <br>
    <v-row class="pt-0 mx-auto mt-n3 justify-space-around">
      <v-col cols="12" sm="1" class="ml-2 mt-3 mr-n2" v-if="!readonlyType && !notUser">
        <FirmFilesUpload v-bind="{ fid }" @filesUploaded="filesUploaded"/>
      </v-col>
      <v-col cols="12" :sm="$_can('firmfile-upload') ? 11 : 12">
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete class="py-0 mx-2 mb-n4"
                            dense
                            @input="filesArray = _.union(filesArray, attachedForms),
                              filesArray = _.uniqBy(filesArray, 'loid'), scroll()"
                            :items="allForms"
                            item-text="file_description"
                            item-value="loid"
                            label="Attach Form/s:"
                            multiple
                            :readonly="readonlyType || notUser"
                            return-object
                            style="cursor: pointer"
                            v-model="attachedForms"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" id="resultId">
            <v-autocomplete class="py-0 mx-2 mb-n4"
                            dense
                            @input="filesArray = _.union(filesArray, attachedFiles),
                              filesArray = _.uniqBy(filesArray, 'loid'), scroll()"
                            :items="firmFiles"
                            item-text="datefile"
                            item-value="loidfid"
                            label="Attach File/s:"
                            multiple
                            :readonly="readonlyType || notUser"
                            return-object
                            style="cursor: pointer"
                            v-model="attachedFiles"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import FirmFilesUpload from "./FirmFilesUpload";
  import moment from 'moment';
  import { FileobjectsAPIService } from '@/servicehandlers/FileobjectsAPIService';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
  import { PoliciesAPIService } from "@/servicehandlers/PoliciesAPIService";

  const firmsAPIService = new FirmsAPIService();
  const file_objects = new FileobjectsAPIService();
  const notesAPIService = new NotesAPIService();
  const policiesAPIService = new PoliciesAPIService();

  export default {
    name: "EditFiles",
    components: { FirmFilesUpload, draggable },
    props: {
      fid: Number,
      groups: Array,
      type: String,
      typeId: Number,// this is note.nid, policy.pid, etc.
      notUser: {default: false},
      readonlyType: {default: false},
    },
    data: () => ({
      allForms: [],
      attachedFiles: [],
      attachedForms: [],
      filesArray: [],
      filesChanged: [],
      filesToUpload: [],
      firmFiles: [],
      initial: true,
      loading: true,
      uploadedFiles: [],
      url: process.env.VUE_APP_UW_URL,
    }),

    watch: {
      filesArray() {// emit updated filesArray, and connect all the files in filesArray to note
        this.$emit('filesArrayUpdated', this.filesArray);
        if (this.initial === true) {// initial loading of filesArray, so already in order
          this.initial = false;
          return;
        }
        let loids = this.filesArray.map(a => a.loid);
        // update files for note/policy, then update forms & files dropdowns, as a file may have been added or removed
        if (this.type === 'note') notesAPIService.updateFiles(this.typeId, loids, this.$router).then(() => {
          this.getFirmFiles(this.fid);
          file_objects.getAllForms(this.$router).then((forms) => { this.allForms = forms });
        });
        if (this.type === 'policy') policiesAPIService.updateFiles(this.typeId, loids, this.$router).then(() => {
          this.getFirmFiles(this.fid);
          file_objects.getAllForms(this.$router).then((forms) => { this.allForms = forms });
        });
      },

      filesChanged() {// loop through filesChanged, find file_description for that loid, then update file
        if (!this.filesChanged.length) return;
        for (let loid of this.filesChanged) {
          // get file_description
          const file = { file_description: this.filesArray.find(x => x.loid === loid).file_description };
          file_objects.updateFileObject(loid, file, this.$router).then((response) => {
            this.filesChanged = [];// reset
          }).catch((error) => {
            console.debug('error: ', error);
          });
        }
      },
    },

    mounted() {
      this.initial = true;
      if (!this.filesArray.length && this.fid && this.typeId) this.getAllFiles(this.fid, this.typeId);
    },

    methods: {
      connectUploadedFiles() {
        for (let fle of this.uploadedFiles) {
          this.filesArray.push(fle);// this will trigger watch filesArray which will connect to note or policy
        }
        // if user chooses same file more than once, remove
        this.filesArray = this._.uniqBy(this.filesArray, 'loid');
      },

      disconnectFile(file) {
        // remove from attachedForms/Files if in them, remove from filesArray
        if (this.attachedForms.indexOf(file) !== -1) this.attachedForms.splice(this.attachedForms.indexOf(file), 1)
        if (this.attachedFiles.indexOf(file) !== -1) this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1)
        this.filesArray.splice(this.filesArray.indexOf(file), 1);// triggers watch filesArray, connects new filesArray
        this.scroll();// scrolls back to bottom
      },

      filesUploaded(files) {
        this.uploadedFiles = files;
        this.connectUploadedFiles();
      },

      getAllFiles(fid, id) {
        this.getFirmFiles(fid);
        this.loading = true;
        if (this.type === 'policy') {
          policiesAPIService.getPolicyFiles(id, this.$router).then((files) => {
            this.filesArray = files;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
        }
        if (this.type === 'note') {
          notesAPIService.getNoteFiles(id, this.$router).then((files) => {
            this.filesArray = files;
            this.loading = false;
            for (let file of this.filesArray) {
              if (!file.file_description) {
                file.file_description = file.filename;
                this.filesChanged.push(file.loid);
              }
            }
          })
          .catch((error) => {
            this.loading = false;
          });
        }
        file_objects.getAllForms(this.$router).then((forms) => {
          if (!!forms) this.allForms = forms;
        });
      },

      getFirmFiles(fid) {
        firmsAPIService.getFirmFiles(fid, this.$router).then((data) => {
          if (!!data) {
            this.firmFiles = data.reduce((files, file) => {
              if (file.file_type_description === 'Admin' && !this.groups.includes('admin')) return files;
              file.formattedDate = moment(file.dated,
                  'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A');
              file.datefile = `${file.formattedDate}\xa0\xa0\xa0${file.file_description}`;
              files.push(file);
              return files;
            }, []);
          }
        });
      },

      scroll() {
        this.$nextTick(() => window.scrollTo(0,document.body.scrollHeight));
      },

    },
  }
</script>

<style scoped>

</style>