import CommonAPIService from './CommonHandler';

export class PeopleAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }
  getPeople(router) {
    const fields = 'plid,given,surname,sort,salutation,people.email';
    const order = 'sort,surname,given';
    const url = `/api/people?fields=${fields}&order=${order}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPeopleByCid(cid, router) {
    const fields = 'plid,given,surname,sort,salutation,people.email';
    const order = 'surname,given';
    const url = `/api/people?fields=${fields}&cid=${cid}&order=${order}`;
    return this.CommonAPIService.getCall(url, router);
  }

  /**
   * @param name {string} - The name of the person to search
   * @param router {Object}
   * @returns {Promise<*> | *}
   * @example PeopleAPIService.findPeopleByName('john', this.$route);
   */
  findPeopleByName(name, router) {
    const fields = 'plid,given,surname,contacts.sort,people.salutation,people.email,firms.sort';
    const order = 'surname,given';
    const searchFields = 'given,surname,direct,people.fax,contacts.fax,home,mobile,contacts.sort';
    const limit = 100;
    const url = `/api/people?fields=${fields}&order=${order}&search=${name}&searchFields=${searchFields}&limit=${limit}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPerson(id, router) {
    const fields = 'plid,fid,prefix,given,surname,salutation,suffix,ssn,email,mobile,home,direct,ext,fax,licensed,hired,' +
      'born,role,hours,ce';
    const url = `/api/people/${id}?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getSearchPeople(search, router) {
    const url = `/api/people/search/${search}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getPeopleByType(search, router) {
    const url = `/api/people/${search}`;
    return this.CommonAPIService.getCall(url, router);
  }

  updatePerson(id, person, router) {
    const url = `/api/people/${id}`;
    return this.CommonAPIService.putCall(url, person, router);
  }

  deletePerson(id, router) {
    const url = `/api/people/${id}`;
    return this.CommonAPIService.deleteCall(url, router);
  }

  createPerson(person, router) {
    const url = '/api/people';
    return this.CommonAPIService.postCall(url, person, router);
  }

  createPeople(people, router) {
    const url = '/api/people';
    return this.CommonAPIService.postCall(url, people, router);
  }

  updatePeople(people, router) {
    const url = '/api/people';
    return this.CommonAPIService.putCall(url, people, router);
  }

  async createOrUpdate(people, router) {
    const peopleToCreate = people.filter(person => !('plid' in person));
    const peopleToUpdate = people.filter(person => 'plid' in person);
    const promise = [ this.createPeople(peopleToCreate, router), this.updatePeople(peopleToUpdate, router) ];
    return (await Promise.all(promise)).flat();
  }

  /**
   * @param name {string} - The name of the person to search
   * @param router {Object}
   * @returns {Promise<*> | *}
   * @example PeopleAPIService.searchPerson('john', this.$route);
   */
  searchPerson(name, router) {
    const fields = 'plid,given,surname,contacts.sort,people.salutation,people.email,firms.sort';
    const order = 'surname,given';
    const searchFields = 'given,surname,direct,people.fax,contacts.fax,home,mobile,contacts.sort';
    const limit = 100;
    const url = `/api/people?fields=${fields}&order=${order}&search=${name}&searchFields=${searchFields}&limit=${limit}`;
    return this.CommonAPIService.getCall(url, router);
  }
}

export default PeopleAPIService;
