<template>
<div>
  <div class="headline px-12 py-6">Search People</div>
  <v-card v-if="!loggedIn" class="pt-3" align="center" height="50px" tile dark block color='primary'>YOU HAVE BEEN LOGGED OUT</v-card>
  <v-card style="border-top: 1px solid white !important;" v-sticky sticky-z-index="2" sticky-offset={top:48}
          class="pa-1 d-flex flex-row justify-space-around" tile dark block color='primary'>
      <v-btn  class="px-1 hidden-xs-only" x-large  tile text type="submit" value="Submit" v-on:click="searchPeople()">Run People Search</v-btn>
      <v-btn  class="px-1 hidden-sm-and-up" large  tile text type="submit" value="Submit" v-on:click="searchPeople()">Run People Search</v-btn>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn  class="px-1 hidden-xs-only" x-large tile text v-on="on" v-on:click="clearSearch()">Clear Search</v-btn>
          <v-btn  class="px-1 hidden-sm-and-up" large tile text v-on="on" v-on:click="clearSearch()">Clear Search</v-btn>
        </template>
        <span>Clear all the search fields</span>
      </v-tooltip>
    </div>

  </v-card>
  <br>


  <v-card tile class="px-6">
    <v-container fluid pa-0>
      <v-row>
        <v-col class="px-12" cols="12" lg="4">
          <v-row>
            <v-text-field  v-model="searchObj.given" @keyup.enter="searchPeople()"
                           label="Given Name" spellcheck="false"></v-text-field>
          </v-row>
        </v-col>
        <v-col  class="px-12" cols="12" lg="4">
          <v-row>
            <v-text-field  v-model="searchObj.surname" @keyup.enter="searchPeople()"
                           label="Surname" spellcheck="false"></v-text-field>
          </v-row>
        </v-col>
        <v-col  class="px-12" cols="12" lg="4">
          <v-row>
            <v-text-field  v-model="searchObj.email" @keyup.enter="searchPeople()"
                           label="Email" spellcheck="false"></v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-12" cols="12" lg="4">
          <v-row>
            <v-text-field  v-model="searchObj.mobile" @keyup.enter="searchPeople()"
                           label="Mobile" spellcheck="false"></v-text-field>
          </v-row>
        </v-col>
        <v-col  class="px-12" cols="12" lg="4">
          <v-row>
            <v-text-field  v-model="searchObj.direct" @keyup.enter="searchPeople()"
                           label="Direct" spellcheck="false"></v-text-field>
          </v-row>
        </v-col>
        <v-col  class="px-12" cols="12" lg="4">
          <v-row>
            <v-text-field  v-model="searchObj.fax" @keyup.enter="searchPeople()"
                           label="Fax" spellcheck="false"></v-text-field>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <br>
<br>


  <b-container>
  <b-row>
    <b-col>
      <div class="form-group">
        <label for="given">Given Name:</label>
        <input v-model="searchObj.given" type="text" class="form-control" id="given">
      </div>
    </b-col>
    <b-col>
      <div class="form-group">
        <label for="surname">Surname:</label>
        <input v-model="searchObj.surname" type="text" class="form-control" id="surname">
      </div>
    </b-col>
    <b-col>
      <div class="form-group">
        <label for="email">Email:</label>
        <input v-model="searchObj.email" type="text" class="form-control" id="email">
      </div>
    </b-col>
    <b-col>
      <div class="form-group">
        <label for="mobile">Mobile:</label>
        <input v-model="searchObj.mobile" type="text" class="form-control" id="mobile">
      </div>
    </b-col>
    <b-col>
      <div class="form-group">
        <label for="direct">Direct:</label>
        <input v-model="searchObj.direct" type="text" class="form-control" id="direct">
      </div>
    </b-col>
    <b-col>
      <div class="form-group">
        <label for="fax">Fax:</label>
        <input v-model="searchObj.fax" type="text" class="form-control" id="fax">
      </div>
    </b-col>
<!--    <b-col>-->
<!--      <div class="form-group">-->
<!--        <button class="btn btn-primary btn-lg" @click="searchPeople">search</button>-->
<!--      </div>-->
<!--    </b-col>-->
  </b-row>
</b-container>
  <v-card v-if="this.searchResultsLoaded" class="pt-3" align="center" height="50px" tile dark block
          color='primary'>Total Records: {{ this.totalRecords }}</v-card>
  <br>

<!--  :custom-sort="customSort"-->
<!--  :server-items-length="this.totalRecords"-->
  <v-data-table class="fullSizeTable elevation-24" :loading="this.loading"
                loading-text="Loading...Please wait"
                fixed-header
                :headers="colHeaders"
                :custom-sort="customSort"
                :mobile-breakpoint="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :options.sync="options"
                :items="items"
                :footer-props="{'items-per-page-options': [ 15, 25, 50, 75, 100] }"
                :items-per-page="15">
  </v-data-table>
  <b-container>
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="items"
          :fields="fields">
        </b-table>
        <b-pagination
          :total-rows="totalRecords"
          :per-page="perPage"
          v-model="currentPage">
        </b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
  import { CommonAPIService } from "@/servicehandlers/CommonHandler";
  import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";

  const peopleApiService = new PeopleAPIService();
  const apiService = new CommonAPIService();

  export default {
    name: "People",
    data() {
      return {
        loading: false,
        searchResultsLoaded: false,
        searchObj: {},
        newSearchObj: {},
        searchItems: {},
        items: [],
        errors: [],
        colHeaders: [],
        totalRecords: 0,
        totalPages: 0,
        currentPage: 1,
        loggedIn: false,
        perPage: 50,
        sortDesc: false,
        sortBy: null,
        fields: { // required for doing a lookup and easy setting of the objects
          given: { label: 'Given', sortable: true },
          surname: { label: 'Surname', sortable: true },
          email: { label: 'Email', sortable: true },
          mobile: { label: 'Mobile', sortable: true },
          direct: { label: 'Direct', sortable: true },
          fax: { label: 'Fax', sortable: true },
          firm: { key: 'firm.sort', label: 'Firm Name', sortable: true },
        },
        options: {
          "page": 1,
          "itemsPerPage": 100,
          "sortBy": [null],
          "sortDesc": [false],
        },
      };
    },

    created() {
      this.rightsCheck();
    },

    watch: {
      options: {
        // when options change, send new values in searchItems to api
        handler () {
         // set new search options and call API
          this.setSearchItems();
          this.callApi();
        },
        deep: true,
      },
      currentPage: function () {
        this.searchPeople();
      },
    },

    methods: {
      rightsCheck() {
        // do I have rights to view this page?
        let seePage = false;
        const rights = this.$store.getters.scopes;
        for (let i = 0; i < rights.length; i += 1) {
          if (rights[i].name === 'person-view') {
            seePage = true;
          }
        }

        if (!seePage) {
          localStorage.removeItem('jwtToken');
          this.$store.dispatch('LOGOUT');
          this.$router.push({
            name: 'Login',
          });
        }
      },

      customSort: function (items, index, isDesc) {
        if (index[0]) {
          let key = index[0];
          items.sort((a, b) => {
            let valA = '';
            let valB = '';
              // sort alphanumerically
              valA = a[key];
              valB = b[key];
              if (isDesc[0] === false) {
                if (valA) {
                  return valA.localeCompare(valB);
                } else {
                  return -1;
                }
              } else {
                if (valB) {
                  return valB.localeCompare(valA);
                } else {
                  return -1;
                }
              }
          });
          return items;
        } else return items;
      },

      clearSearch() {
        this.searchObj = {};
        this.newSearchObj = {};
        this.searchItems = {};
        this.items = [];
        this.totalPages = 0;
        this.currentPage = 1;
        this.perPage = 100;
        this.sortBy = null;
        this.sortDesc = false;
        this.totalRecords = 0;
        this.colHeaders = [];
        this.loading = false;
        this.searchResultsLoaded = false;
      },

      setSearchItems() {
        this.newSearchObj.page = this.options.page;
        this.newSearchObj.perPage = this.options.itemsPerPage;
        this.newSearchObj.sortDesc = this.options.sortDesc[0];
        this.newSearchObj.sortBy = this.options.sortBy[0];
      },
      callApi(){
        let newSearchObj = this.newSearchObj;
        this.loading = true;
        peopleApiService.searchPeople(newSearchObj, this.$router)
          .then((results) => {
            this.searchResultsLoaded = true;
            this.loading = false;
            this.items = results['result'];
            this.totalPages = results['totalpages'];
            this.totalRecords = results['count'];
          })
          .catch((error) => {
            this.errors.push(error);
          });
      },

      searchPeople() {
        this.colHeaders = [
          { text: 'Given',    value: 'given',    width: "140px", align: 'start'},
          { text: 'Surname',  value: 'surname',  width: "120px", align: 'start'},
          { text: 'Email',    value: 'email',    width: "120px", align: 'start'},
          { text: 'Mobile',   value: 'mobile',   width: "120px", align: 'start'},
          { text: 'Direct',   value: 'direct',   width: "120px", align: 'start'},
          { text: 'Fax',      value: 'fax',      width: "120px", align: 'start'},
          { text: 'Firm Name',value: 'firm.sort',width: "400px", align: 'start'},
        ];
        this.newSearchObj = {searchItems: {},};
        // code for cleaning up objects that have no value in them
        Object.keys(this.searchObj).forEach((key) => {
          let value = this.searchObj[key];
          if ( value.length >= 1 ) {
            this.newSearchObj.searchItems[key] = '%' + value + '%';
          } else {
            delete this.searchObj[key];
          }
        });
        this.setSearchItems();
        this.callApi();
      }
    }
  }
</script>

<style scoped>

</style>
