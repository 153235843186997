<template>
  <v-row class="px-4" dense>

    <div class="col-4">
      <v-row justify="center" class="mb-n5 closeLabel">
        <information-dialog-icon icon-style="margin-top: -6px" title="Users Info">
          <p>Drag a group and drop it under a user to add it to that user.</p>
          <p>Hover over a group name to see its users.</p>
          <p><v-icon>mdi-lock-outline</v-icon>Symbol is for disabled (locked) users.</p>
          <p><v-icon>mdi-lock-open-variant-outline</v-icon>Symbol is for enabled (unlocked) users.</p>
          <p>Click <v-icon>mdi-lock-open-variant-outline</v-icon> to disable a user.</p>
          <p>Click <v-icon>mdi-lock-outline</v-icon> to enable a user.</p>
          <p>Click <v-icon>mdi-content-copy</v-icon> to copy a user.</p>
          <p>Click <v-icon>mdi-arrow-up-down</v-icon> to open/close a user.</p>
          <p>Click <v-icon>mdi-checkbox-blank-outline</v-icon>All -- to toggle seeing all/enabled users.</p>
        </information-dialog-icon>
        <h4>Users&nbsp</h4>
        <v-checkbox v-model="allUsers" dense color="secondary" class="ml-1 mt-n1" label="All"></v-checkbox>
      </v-row>
      <v-row justify="center">
        <v-btn x-small color="secondary" class="mx-1"
               @click="toggleOpen('usersExpanded', true)">open all</v-btn>
        <v-btn x-small color="secondary" class="mx-1"
               @click="toggleOpen('usersExpanded', false)">close all</v-btn>
        <v-tooltip bottom class="ml-n2 pl-0 pr-2">
          <template v-slot:activator="{ on }">
            <v-btn x-small fab color="secondary"
                   @click="userAdd"
                   v-on="on" class="ml-1 mt-n1"
                   elevation="4"><v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add user</span>
        </v-tooltip>
      </v-row>

      <v-text-field class="mb-n3" clearable
                    dense v-model="search"
                    :placeholder="`Search ${list}..`"></v-text-field>
      <v-card :max-height="scrollBoxHeight" class="scroll mt-1">
        <v-card
          class="px-3 pt-1 pb-0"
          :key="userObject.id + refreshUsers"
          style="margin-top: 1px; margin-bottom:1px; padding-bottom: 0px"
          v-for="userObject in filteredUsers">
          <span class="mt-n1 mb-n2 py-0">
            <span class="justify-start">
              <v-btn x-small icon class="ml-n2 mr-n1" v-if="userObject.enabled"
                     @click="userConfirmDisableDialog = true, userToDisable = userObject"
              ><v-icon>mdi-lock-open-variant-outline</v-icon></v-btn>
              <v-btn x-small icon class="ml-n2 mr-n1" v-else
                     @click="userObject.enabled = true, userUpdateInDatabase(userObject)">
                <v-icon>mdi-lock-outline</v-icon></v-btn>
              <v-btn icon x-small>
                <v-icon @click="userEdit(userObject)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn x-small icon style="margin-left: -2px; margin-right: -1px">
                <v-icon @click="userCopy(userObject)">mdi-content-copy</v-icon>
              </v-btn>
              <v-btn x-small icon style="margin-left: -2px"
                     :disabled="!(!!userObject.authGroups && userObject.authGroups.length > 2)"
                     @click="usersExpanded[userObject.id] = !usersExpanded[userObject.id], refresh = !refresh">
                          <v-icon>mdi-arrow-up-down</v-icon>
              </v-btn>
              {{userObject.username}}
            </span>
          </span>

          <v-card :height="usersExpanded[userObject.id] || !userObject.authGroups
              || (!!userObject.authGroups && userObject.authGroups.length < 2) ? '100%' : 48"
                  :key="userObject.username + refresh" width="90%"
                  class="pt-2 pb-1 pl-2 mb-n5 scroll elevation-0">
            <draggable
              :emptyInsertThreshold="20"
              :group="{ name: 'groups', pull: 'clone', put: true }"
              :key="toggleKey + refresh"
              :list="userObject.authGroups"
              :move="moveDisallow"
              v-bind="{ghostClass: 'ghost'}"
              v-if="userObject.enabled === true"
            >
              <div v-for="(group, index) in userObject.authGroups" style="white-space: nowrap"
                   class="my-n3 py-0 secondary--text darken-4 font-weight-bold"
                   :key="group.name">
                <v-btn text class="ml-n2 mr-n6 secondary--text darken-4 font-weight-bold "
                       @click="userRemoveFromGroup(userObject, group.id),
                        userObject.authGroups.splice(index, 1), toggleKey = !toggleKey">X</v-btn>
                <span>{{ group.name }}</span>
              </div>
            </draggable>
          </v-card>
          <br>
        </v-card>
      </v-card>
    </div>

    <div class="col-4">
      <v-row justify="center" class="mb-n5 closeLabel">
        <information-dialog-icon icon-style="margin-top: -6px" title="Groups Info">
          <p>Drag a scope and drop it under a group to add it to that group.</p>
          <p>Drag a group and drop it under a user to add it to that user.</p>
          <p>Hover over a group name to see its users.</p>
          <p><v-icon>mdi-lock-outline</v-icon>Symbol is for disabled (locked) groups.</p>
          <p><v-icon>mdi-lock-open-variant-outline</v-icon>Symbol is for enabled (unlocked) groups.</p>
          <p>Click <v-icon>mdi-lock-open-variant-outline</v-icon> to disable a group. This will remove its users and scopes.</p>
          <p>Click <v-icon>mdi-lock-outline</v-icon> to enable a group.</p>
          <p>Click <v-icon>mdi-content-copy</v-icon> to copy a group.</p>
          <p>Click <v-icon>mdi-arrow-up-down</v-icon> to open/close a group.</p>
          <p>Click <v-icon>mdi-checkbox-blank-outline</v-icon>All -- to toggle seeing all/enabled groups.</p>
        </information-dialog-icon>
        <h4>Groups&nbsp</h4>
        <v-checkbox v-model="allGroups" dense color="secondary" class="ml-1 mt-n1" label="All"></v-checkbox>
      </v-row>
      <v-row justify="center">
        <v-btn x-small color="secondary" class="mx-1"
               @click="toggleOpen('groupsExpanded', true)">open all</v-btn>
        <v-btn x-small color="secondary" class="mx-1"
               @click="toggleOpen('groupsExpanded', false)">close all</v-btn>
        <v-tooltip bottom class="ml-n2 pl-0 pr-2">
          <template v-slot:activator="{ on }">
            <v-btn x-small fab color="secondary" @click="groupAdding = true, groupAddDialog = true" v-on="on"
                   class="ml-1 mt-n1" elevation="4">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add group</span>
        </v-tooltip>
      </v-row>
      <v-text-field class="mb-n3" clearable @click:clear="searchGroups = ''" dense
                    v-model="searchGroups" placeholder="Search groups..">
      </v-text-field>
      <v-card :max-height="scrollBoxHeight" class="scroll mt-1">
        <draggable
          @end="usersOnDragend"
          filter=".ignore"
          :group="{ name: 'groups', pull: 'clone', put: false }"
          :key="groups.id"
          :list="filteredGroups"
          :move="usersMoveCheckIfToGroupsIn"
          :scroll-sensitivity="200"
          :sort="false"
        >
          <v-card class="px-1 pt-1 pb-0"
                  :key="groupObject.name + groupObject.scopes"
                  style="max-width: 100%;overflow-x: hidden;cursor: pointer; margin-top: 1px; margin-bottom:1px; padding: 0px"
                  v-for="groupObject in filteredGroups">
            <v-row class="mt-n1 pt-2 pb-1" :class="{'ignore': groupObject.active !== true}">
              <v-btn x-small icon class="ml-4" v-if="groupObject.active"
                     :disabled="groupObject.name === 'admin'"
                     @click="groupConfirmDisableDialog = true, groupToDisable = groupObject"
              ><v-icon>mdi-lock-open-variant-outline</v-icon></v-btn>
              <v-btn x-small icon class="ml-4" v-else
                     @click="groupObject.active = true, groupUpdateInDatabase(groupObject)">
                <v-icon>mdi-lock-outline</v-icon></v-btn>
              <v-btn x-small icon style="margin-left: -1px; margin-right: -1px">
                <v-icon @click="groupToCopy = groupObject, groupAddDialog = true, groupCopying = true">
                  mdi-content-copy</v-icon>
              </v-btn>
              <v-btn @click="groupsExpanded[groupObject.id] = !groupsExpanded[groupObject.id],
                     refresh = !refresh"
                     :disabled="!(!!groupObject.scopes && groupObject.scopes.length > 2)"
                     icon
                     x-small
              ><v-icon>mdi-arrow-up-down</v-icon>
              </v-btn>
              <span v-if="!groupDrag" :key="refreshUsersTips" style="margin-top: -3px">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <div >
                      <span v-on="on" class="secondary--text darken-4 font-weight-bold justify-start">
                        {{groupObject.name}}</span>
                    </div>
                  </template>
                  <div v-if="groupObject.users.length !== 0">
                    <div v-for="user in groupObject.users" :key="user.name">
                      {{ user.name }}
                    </div>
                  </div>
                  <div v-else>no users</div>
                </v-tooltip>
              </span>
              <span class="secondary--text darken-4 font-weight-bold justify-start"
                    v-else style="margin-top: -3px">{{ groupObject.name }}
              </span>
            </v-row>
            <div style="margin-top: -9px">
              <v-card :height="groupsExpanded[groupObject.id] ||  !groupObject.scopes
                        || (!!groupObject.scopes && groupObject.scopes.length < 2)? '100%' : 48"
                      :key="groupObject.name + refresh" class="pt-2 pb-1 mb-n5 scroll elevation-0"
                      v-if="groupObject.active === true" width="90%" style="margin-top: 8px">
                <draggable
                  class="hide"
                  :emptyInsertThreshold="20"
                  :group="{ name: 'scopes', pull: 'clone', put: true }"
                  :key="toggleKey"
                  :list="groupObject.scopes"
                  :move="moveDisallow"
                >
                  <div
                    class="ml-0 my-n3 pl-0 py-0"
                    :key="scope.name"
                    style="white-space: nowrap"
                    v-for="(scope, index) in groupObject.scopes">
                    <v-btn
                      class="ml-n2 mr-n6"
                      @click="groupDeleteScope(groupObject, groupObject.scopes[index].id),
                        groupObject.scopes.splice(index, 1), toggleKey = !toggleKey"
                      text
                    >X
                    </v-btn>
                    {{ scope.name }}
                  </div>
                </draggable>
              </v-card>
            </div>
            <br>
          </v-card>
        </draggable>
      </v-card>
    </div>

    <div class="col-4">
      <v-row justify="center">
        <information-dialog-icon icon-style="margin-top: -6px" title="Scopes Info">
          <p>Drag a scope and drop it under a group to add it to that group.</p>
          <p>Hover over a scope to see its groups.</p>
          <p>Click "SHOW DETAIL" for scopes' descriptions.</p>
        </information-dialog-icon>
        <h4 class="ml-1"> Scopes&nbsp</h4>
      </v-row>
      <v-row justify="center" class="mb-n1">
        <v-btn x-small color="secondary" class="mx-1"
               @click="showDetails = true">show detail</v-btn>
        <v-btn x-small color="secondary" class="mx-1"
               @click="showDetails = false">hide detail</v-btn>
        <v-tooltip bottom class="ml-n2 pl-0 pr-2" v-if="env === 'development'">
          <template v-slot:activator="{ on }">
            <v-btn x-small fab color="secondary" @click="scopeAddDialog = true" v-on="on"
                   class="ml-1 mt-n1" elevation="4">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add scope</span>
        </v-tooltip>
      </v-row>
      <v-text-field class="mt-0 mb-n4" dense clearable @click:clear="searchScopes = ''"
                    v-model="searchScopes" placeholder="Search scopes..">
      </v-text-field>
      <v-card :max-height="scrollBoxHeight" class="mt-2 scroll">
        <draggable
          @end="groupsOnDragend"
          :forceFallback="true"
          :group="{ name: 'scopes', pull: 'clone', put: false }"
          :key="scopes.id"
          :list="filteredScopes"
          :move="groupsMoveCheckIfToScopesIn"
          :sort="false">
          <div v-for="(scope, index) in filteredScopes"
               style="cursor: pointer; white-space: nowrap"
               class="px-3 py-1"
               :key="scope.name">
            <v-btn
              class="ml-n6 mr-n6 mt-0"
              @click="scopeDelete(scope)"
              text
              v-if="env === 'development'"
            >X
            </v-btn>
            <span v-if="!scopeDrag" :key="refreshGroupsTips">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span v-on="on"><span>{{ scope.name }}</span></span>
                </template>
                <div v-if="!!scope.groups && scope.groups.length !== 0">
                  <div v-for="group in scope.groups" :key="group.name">{{ group.name }}</div>
                </div>
                <div v-else>no groups</div>
              </v-tooltip>
            </span>
            <span v-else>{{ scope.name }}</span>
            <span v-if="showDetails">
              <span style="color: grey" class="caption"> -- {{scope.description}}</span>
            </span>
          </div>
        </draggable>
      </v-card>
    </div>

    <!--    <rawDisplayer :value="users" title="Users"/>-->
    <!--    <rawDisplayer :value="groups" title="Groups"/>-->
    <!--    <rawDisplayer :value="scopes" title="Scopes"/>-->

    <!-- Dialogs -->

    <v-dialog v-model="changingPassword" max-width="550" :key="refresh + 'password'">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="6">
            <v-text-field label="Enter Old Password"
                          :append-icon="hideOld ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="function() {hideOld = !hideOld}"
                          :type="hideOld ? 'password' : 'text'"
                          v-model="userToAdd.password"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Enter New Password"
                          :append-icon="hideNew ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="function() {hideNew = !hideNew}"
                          :type="hideNew ? 'password' : 'text'"
                          :rules="rules.changingPassword"
                          v-model="confirmPassword"></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-3" text
                 @click="changingPassword = false">
            Cancel
          </v-btn>
          <v-btn @click="userUpdatePassword"
                 color="secondary darken-3"
                 :disabled="!confirmPassword || !userToAdd.password"
                 text
                 v-if="changingPassword"
          >Change password</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDialog" persistent max-width="550" :key="refresh + 'user'">
      <v-card>
        <v-card-title>
          <span v-if="userAdding">New User</span>
          <span v-if="userCopying">New user from copy of
            <span class="secondary--text"> {{userToCopy.username}}</span>
            <div class="caption mb-n2">
              This user will be in the same groups and have the same scopes as {{userToCopy.username}}
            </div>
          </span>
          <span v-if="userEditing">Edit
            <span class="secondary--text">{{userToAdd.username}}</span>
          </span>
        </v-card-title>
        <v-card-text><br>
          <v-text-field class="mb-n2 pb-0"
                        label="Username"
                        v-if="!userEditing"
                        :rules="rules.username"
                        v-model="userToAdd.username"></v-text-field>
          <v-row v-if="changingPassword" class="py-4"></v-row>
          <v-row v-if="userAdding || userCopying">
            <v-col cols="6">
              <v-text-field label="Enter Password"
                            :append-icon="hideOld ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="function() {hideOld = !hideOld}"
                            :type="hideOld ? 'password' : 'text'"
                            v-model="userToAdd.password"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Confirm Password"
                            :append-icon="hideNew ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="function() {hideNew = !hideNew}"
                            :type="hideNew ? 'password' : 'text'"
                            :rules="rules.confirmPassword"
                            v-model="confirmPassword"></v-text-field>
            </v-col>
          </v-row>
          <v-row  v-if="userEditing" style="margin-left: 1px" class="mt-n9 mb-n6 pt-0">
            <v-checkbox
              color="secondary"
              v-model="changingPassword"
              v-if="userEditing"
              label="Change password"></v-checkbox>
          </v-row>
          <v-row v-if="changingPassword" class="py-12"></v-row>
          <v-row class="mt-n5 mb-n3">
            <v-col cols="5">
              <v-text-field label="Timeout (hours)"
                            :min="1"
                            :step="1"
                            type="number"
                            v-model="userToAdd.hours"></v-text-field>
            </v-col>
            <v-col cols="1" class="h4 mt-4">:
            </v-col>
            <v-col cols="6">
              <v-text-field label="Timeout (minutes)"
                            :min="1"
                            :step="1"
                            type="number"
                            v-model="userToAdd.minutes"></v-text-field>
            </v-col>
          </v-row>
          <v-combobox v-model="userToAdd.ip_restrictions" multiple chips type="text" clearable
                      label="Optional: Select IP address or type new IP and press return" :items="ips"></v-combobox>
          <v-checkbox class="mb-n4" color="secondary" v-model="userToAdd.multiple_logins"
                      label="Multiple Logins Allowed"></v-checkbox>
          <v-checkbox v-if="!userEditing" class="mt-n4" color="secondary" v-model="userToAdd.enabled"
                      label="Enable this user"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-3" text
                 @click="userDialogRefresh(true)">
            Cancel
          </v-btn>
          <v-btn
            @click="userSave"
            color="secondary darken-3"
            :disabled="!userEditing ?
                     (!userToAdd.username
                  || !confirmPassword
                  || !userToAdd.password
                  || !(userToAdd.password === confirmPassword)) : false"
            text
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="groupAddDialog" persistent max-width="500" :key="refresh + 'group'">
      <v-card>
        <v-card-title>
          <span v-if="groupAdding">New Group</span>
          <span v-if="groupCopying">New group from copy of
            <span class="secondary--text"> {{groupToCopy.name}}</span>
            <div class="caption mb-n2">
              This group will have the same scopes as {{groupToCopy.name}}
            </div>
          </span>
        </v-card-title>
        <v-card-text class="pa-12">
          <v-text-field
            @keyup.enter="!!newGroupName && !groupNamesTaken.includes(newGroupName) ? groupSave() : ''"
            label="Enter a name for the new group"
            :rules="rules.groupName"
            v-model="newGroupName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-3" text
                 @click="groupAddDialog=false,newGroupName='',groupAdding=false,groupCopying=false,refresh = !refresh">
            Cancel
          </v-btn>
          <v-btn color="secondary darken-3" text
                 :disabled="!newGroupName || groupNamesTaken.includes(newGroupName)"
                 @click="groupSave">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="scopeAddDialog" persistent max-width="500" :key="refresh + 'scope'">
      <v-card>
        <v-card-title>New Scope</v-card-title>
        <v-card-text class="pa-12">
          <v-text-field
            @keyup.enter="scopeSave()"
            label="Enter a name for the new scope"
            v-model="newScopeName"
          ></v-text-field>
          <v-text-field
            @keyup.enter="scopeSave()"
            label="Enter a description for the new scope"
            v-model="newScopeDescription"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-3" text
                 @click="scopeAddDialog=false,newScopeName='',refresh = !refresh">
            Cancel
          </v-btn>
          <v-btn color="secondary darken-3" text
                 :disabled="!newScopeName"
                 @click="scopeSave()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <ActionDialog :value="userConfirmDisableDialog" style="z-index: 99" max-width="700"
                  divider confirm-text="Disable"
                  title="Disable user" close-text="Cancel" confirm-color="error"
                  @confirm="userConfirmDisableDialog = false,
                    userRemoveFromGroups(userToDisable)"
                  @close="userConfirmDisableDialog = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        Are you sure you want to <strong>disable</strong> this user?
        <br><br><strong>{{ userToDisable.username }}</strong><br><br>
        This will <u>remove the user from all groups</u>. Click "DISABLE" below to proceed.
      </v-alert>
    </ActionDialog>

    <ActionDialog :value="groupConfirmDisableDialog" style="z-index: 99" max-width="700"
                  divider confirm-text="Disable"
                  title="Disable user" close-text="Cancel" confirm-color="error"
                  @confirm="groupToDisable.active = false, groupConfirmDisableDialog = false,
                    groupRemoveFromUsersAndScopes(groupToDisable)"
                  @close="groupConfirmDisableDialog = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        Are you sure you want to <strong>disable</strong> this group?
        <br><br><strong>{{ groupToDisable.name }}</strong><br><br>
        This will <u>remove the group from all scopes and users</u>. Click "DISABLE" below to proceed.
      </v-alert>
    </ActionDialog>

    <v-snackbar app color="secondary" v-model="saveSuccess" style="top: 10px;" top>
      Save was successful.
      <v-btn color="white" class="mt-0 ml-12" text @click="saveSuccess = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar app color="secondary" v-model="passwordChangeSuccess" style="top: 10px;" top>
      Password change was successful.
      <v-btn color="white" class="mt-0 ml-12" text @click="passwordChangeSuccess = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar app color="error" v-model="saveError" style="top: 10px;" top>
      Error saving. Please check all fields and try again.
      <v-btn color="white" class="mt-0 ml-9" text @click="saveError = false">Close</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
import {_} from 'vue-underscore';
import ActionDialog from "../ActionDialog";
import AuthGroupAPIService from "@/servicehandlers/AuthGroupAPIService";
import draggable from 'vuedraggable';
import { GroupsAPIService } from '../../servicehandlers/GroupsAPIService';
import InformationDialogIcon from "@/components/InformationDialogIcon";
import loginCheckMixin from '@/mixin';
import RawDisplayer from '@/components/RawDisplayer';
import { ScopesAPIService } from "../../servicehandlers/ScopesAPIService";
import { UsersAPIService } from "../../servicehandlers/UsersAPIService";

const authGroupAPIService = new AuthGroupAPIService();
const groupsAPIService = new GroupsAPIService();
const scopesAPIService = new ScopesAPIService();
const usersAPIService = new UsersAPIService();

export default {
  name: "Users",
  mixins: [loginCheckMixin],
  order: 1,
  components: {
    ActionDialog, draggable, InformationDialogIcon, RawDisplayer
  },
  data() {
    return {
      active: false,
      allGroups: false,
      allUsers: false,
      changingPassword: false,
      confirmPassword: '',
      disallowGroupDrop: false,
      droppedScope: {},
      duplicate: false,
      groupAddDialog: false,
      groupAdding: false,
      groupConfirmDisableDialog: false,
      groupCopying: false,
      groupDrag: false,
      groupDroppedIn: '',
      groupNamesTaken: [],
      groupNewId: '',
      groups: [],
      groupsExpanded: [],
      groupToCopy: {},
      groupToDisable: {},
      hideNew: true,
      hideOld: true,
      groupToAddUserTo: '',
      ips: ['192.168.*.*', '*.*.*.*'],
      list: 'users',
      newGroupName: '',
      newScopeName: '',
      newScopeDescription: '',
      passwordChangeSuccess: false,
      refresh: false,
      refreshUsers: false,
      refreshGroupsTips: false,
      refreshUsersTips: false,
      rules: {
        changingPassword: [
          (v) => !!v || 'New password is required',
        ],
        confirmPassword: [
          (v) => !!v || 'Password Confirmation is required',
          (v) => this.userToAdd.password === this.confirmPassword || 'Entered passwords do not match'
        ],
        groupName: [
          (v) => !!v || 'Group name is required',
          (v) => !this.groupNamesTaken.includes(this.newGroupName) || 'Group name is taken'
        ],
        username: [
          (v) => !!v || 'Username is required',
          (v) => !this.usernamesTaken.includes(this.userToAdd.username) || 'Username is taken'
        ],
      },
      saveError: false,
      saveSuccess: false,
      scopeAddDialog: false,
      scopeDrag: false,
      search: '',
      searchGroups: '',
      searchScopes: '',
      settingGroupsToolTips: false,
      showDetails: false,
      scopes: [],// all scopes listed in the table
      sorting: false,
      toggleKey: false,
      userAdding: false,
      userConfirmDisableDialog: false,
      userCopying: false,
      userDialog: false,
      userDroppedIn: {},
      userEditing: false,
      usernamesTaken: [],
      usernameTaken: false,
      userNewId: '',
      userToDisable: {},
      users: [],
      usersExpanded: [],
      userToAdd: {},
      userToCopy: {},
    }
  },

  computed: {
    env() {
      return process.env.VUE_APP_FEATURES;
    },
    filteredGroups() {
      return this.groups.filter(group => {
        let searchFilter = !!this.searchGroups ? group.name.toLowerCase().includes(this.searchGroups.toLowerCase()) : true;
        let filtered = this.allGroups ? searchFilter : searchFilter && group.active === true;
        return filtered;
      })
    },
    filteredScopes() {
      return this.scopes.filter(scopes => {
        return !!this.searchScopes ? scopes.name.toLowerCase().includes(this.searchScopes.toLowerCase()) : true;
      })
    },
    filteredUsers() {
      return this.users.filter(user => {
        let searchFilter = !!this.search ? user.username.toLowerCase().includes(this.search.toLowerCase()) : true;
        let filtered = this.allUsers ? searchFilter : searchFilter && user.enabled === true;
        return filtered;
      })
    },
    scrollBoxHeight() {// if menu bar is on the side, smaller height offset is needed
      let topOffset = this.$_sideMenu ? 125 : 171;
      return 'calc(100vh - ' + topOffset + 'px)';
    },
  },

  watch: {
    allGroups() {
      this.groupsGet(false);
    },

    allUsers() {
      this.usersGet(false);
    },

    groups() {
      // need watchers to know which group the scope was added/dragged to
      // need to reset watchers if a scope is added or deleted
      this.groups.forEach((val) => {this.$watch(() => val, this.groupsHandleChange, {deep: true});});
    },

    scopes() {
      if (!(this.groups.length > 0) || this.settingGroupsToolTips) return;
      this.groupsSetTooltips();
    },

    users() {
      if (!this.groups) return;
      // need watchers to know which user the group was added/dragged to
      this.users.forEach((val) => {this.$watch(() => val, this.usersHandleChange, {deep: true});});
      this.usersSetTooltips();
    },
  },

  created() {
    document.title = 'Roles';
    this.rightsArray = this.loginCheckMixin(['role-edit']);
    if (!this.rightsArray.includes('role-edit')) {
      localStorage.removeItem('jwtToken');
      this.$store.dispatch('LOGOUT');
      this.$router.push({name: 'Login',});
    }
    this.usersGet(true);// initial state true - all user panels open
    this.groupsGet(true);

    groupsAPIService.getScopes(this.$router).then((scopes) => {
      this.scopes = scopes;
      this.arraySort(this.scopes, 'name');// sort scopes
      if (this.groups) this.groupsSetTooltips();
    }).catch((error) => {
      console.debug('error', error);
    });

    usersAPIService.getUsers(this.$router)// use these to check for new unique username
      .then((all) => {
        for (let user of all) this.usernamesTaken.push(user.username);
      });

    authGroupAPIService.getAuthGroups(this.$router)// use these to check for new unique group name
      .then((all) => {
        for (let group of all) this.groupNamesTaken.push(group.name);
      });
  },

  methods: {

    arraySort(array, field) {
      array.sort((a, b) => {
        const A = a[field].toUpperCase();
        const B = b[field].toUpperCase();
        return (A < B) ? -1 : (A > B) ? 1 : 0;
      });
    },

    //---------  group methods ---------//

    groupRemoveFromScopes: async function(group) {
      for (let scope of group.scopes) {
        try {
          let res = await authGroupAPIService.removeScopeFromAuthGroup(this.$route, scope.id, group.id)
        } catch (e) {
          console.debug('error', e);
        }
      }
    },

    groupRemoveFromUsers: async function(group) {
      // loop through all users and if in group, remove from group
      for (let user of this.users) {
        for (let authGroup of user.authGroups) {
          if (authGroup.id === group.id) {
            try {
              let res = await authGroupAPIService.removeUserFromAuthGroup(this.$route, user.id, group.id)
            } catch (e) {
              console.debug('error', e);
            }
          }
        }
      }
      this.refreshUsersTips = !this.refreshUsersTips;
      this.refreshUsers = !this.refreshUsers;
    },

    groupRemoveFromUsersAndScopes: async function(groupToDisable) {
      // remove scopes and users from group and update group with active = false, then get users
      let group = groupToDisable;
      group.users = [];
      group.active = false;
      await this.groupRemoveFromUsers(group);
      this.groupConfirmDisableDialog = false;
      await this.groupRemoveFromScopes(group);
      this.groupUpdateInDatabase(group);
      this.usersGet();
    },

    groupAddScope(group) {
      this.scopeDrag = false;
      if (!this.duplicate) {
        authGroupAPIService.addScopeToAuthGroup(this.$router, this.droppedScope.id, group.id)
          .then((response) => {
            this.groupsGet();
          })
          .catch((error) => {
            console.debug('error: ', error);
            this.saveError = true;
          });
      }
    },

    groupDeleteScope(group, scopeId) {
      authGroupAPIService.removeScopeFromAuthGroup(this.$route, scopeId, group.id)
        .then((response) => {// find the scope in this.scopes and remove group from tooltip
          for (let scp of this.scopes) {
            if (scp.id === scopeId) scp.groups = scp.groups.filter(grp => grp.name!==group.name);
          }
          this.refreshGroupsTips = !this.refreshGroupsTips;
        })
        .catch((error) => {
          console.debug('error: ', error);
          this.saveError = true;
        });
    },

    groupSave() {
      let group = { name: this.newGroupName };
      authGroupAPIService.addAuthGroup(this.$route, group)
        .then((response) => {
          this.groupNewId = response.id;
          if (this.groupCopying) {// copy all the original scopes to this new group
            for (let scope of this.groupToCopy.scopes) {
              authGroupAPIService.addScopeToAuthGroup(this.$route, scope.id, this.groupNewId)
                .then((response) => {
                  // console.debug('response: ', response);
                })
                .catch((error) => {
                  console.debug('error: ', error);
                });
            }
          }
          this.newGroupName = ''
          this.groupAddDialog = false;
          this.groupAdding = false;
          this.groupCopying = false;
          this.refresh = !this.refresh;
          this.saveSuccess = true;
          this.groupsGet();
        })
        .catch((error) => {
          console.debug('error: ', error);
          this.saveError = true;
        });
    },

    groupsGet(initial) {
      let groupsToGet = this.allGroups ?  'getAuthGroups' : 'getActiveAuthGroups';
      authGroupAPIService[groupsToGet](this.$router).then((groups) => {
        for (let group of groups) this.arraySort(group.scopes, 'name');// sort scopes in groups
        this.groups = groups;
        this.arraySort(this.groups, 'name');// sort groups
        if (initial) {// initially expand all groups
          this.toggleOpen('groupsExpanded', true);
        } else {// just expand added group with this.groupNewId from createGroup response.id
          if (this.groupNewId)  this.groupsExpanded[this.groupNewId] = true;
        }
        this.groupsSetTooltips();
        if (this.users) this.usersSetTooltips();
      }).catch((error) => {
        console.debug('error', error);
      });
    },

    groupsHandleChange(newVal) {
      if (!!this.sorting) {
        this.sorting = false;
        return;
      }
      this.groupDroppedIn = newVal.name;
    },

    groupsMoveCheckIfToScopesIn(evt) {// check if move is to scopes in groups & not duplicate
      this.scopeDrag = true;
      this.duplicate = false;
      for (let scope of evt.relatedContext.list) {
        if (scope.name === evt.draggedContext.element.name) this.duplicate = true;
      }
      let toGroups = evt.relatedContext.component.$attrs.group.name === 'scopes';
      this.droppedScope = evt.draggedContext.element;
      return toGroups && !this.duplicate;
    },

    groupsOnDragend: function(evt) {
      for (let group of this.groups) {
        if (this.groupDroppedIn === group.name) {
          this.arraySort(group.scopes, 'name');// sort scopes in groups, to keep alphabetical
          this.groupAddScope(group);
        }
      }
    },

    groupsSetTooltips() {
      if (!(this.groups.length > 0)) return;
      this.settingGroupsToolTips = true;
      // update groups list for scopes tooltips
      for (let scope of this.scopes) {
        scope.groups = [];
        for (let group of this.groups) {
          for (let scopeInGroup of group.scopes) {
            if (scopeInGroup.name === scope.name) {
              scope.groups.push({name: group.name});
            }
          }
        }
      }
      this.settingGroupsToolTips = false;
    },

    groupUpdateInDatabase(group) {
      authGroupAPIService.updateAuthGroup(this.$router, group.id, group)
        .then((response) => {
          this.groupsGet();
        })
        .catch((error) => {
          console.debug('error: ', error);
          this.saveError = true;
        });
    },


    //---------  scope and helper methods ---------//

    moveDisallow(evt) {
      return false;
    },

    scopeDelete(scope) {
      // delete the scope from all groups and delete scope
      for (let group of this.groups) {
        authGroupAPIService.removeScopeFromAuthGroup(this.$route, scope.id, group.id)
          .then((response) => {
          })
          .catch((error) => {
            console.debug('error: ', error);
          });
      }
      scopesAPIService.deleteScope(scope.id, this.$route)
        .then((response) => {
          groupsAPIService.getScopes(this.$router).then((scopes) => {
            this.scopes = scopes;
            this.arraySort(this.scopes, 'name');// sort scopes
            this.groupsGet();
          }).catch((error) => {
            console.debug('error', error);
          });
        })
        .catch((error) => {
          console.debug('error: ', error);
        });
    },

    scopeSave() {
      let scope = { name: this.newScopeName, description: this.newScopeDescription };
      scopesAPIService.createScope(scope, this.$route)
        .then((response) => {
          this.newScopeName = '';
          this.newScopeDescription = '';
          this.scopeAddDialog = false;
          this.saveSuccess = true;
          // now getScopes to see new scope
          groupsAPIService.getScopes(this.$router).then((scopes) => {
            this.scopes = scopes;
            this.arraySort(this.scopes, 'name');// sort scopes
            if (this.groups) this.groupsSetTooltips();
          }).catch((error) => {
            console.debug('error', error);
          });

        })
        .catch((error) => {
          console.debug('error: ', error);
          this.saveError = true;
        });
    },

    toggleOpen(list, open) {// if open is true, set all list items expand props to true
      for (let item of this[`${list.replace('Expanded', '')}`]) this[list][item.id] = open;
      this.refresh = !this.refresh;
    },


    //---------  user methods ---------//

    userAdd() {
      this.userAdding = true;
      this.userDefaultsSet();
      this.userDialog = true;
    },

    userCopy(user) {// create new user with same authgroups (groups and scopes)
      this.userCopying = true;
      this.userToCopy = user;
      this.userDefaultsSet();
      this.userDialog = true;
    },

    userDefaultsSet() {
      this.userToAdd = {
        enabled: true,
        ip_restrictions: [],
        hours: 0,
        minutes: 15,
        multiple_logins: true,
      };
    },

    userDialogRefresh(cancel) {// get users if dialog not cancelled, reset values in userDialog
      if (!cancel) this.usersGet(false);// update list of users and sort
      this.confirmPassword = '';
      this.changingPassword = false;
      this.userToAdd = {};
      this.userToCopy = {};
      this.userAdding = false;
      this.userEditing = false;
      this.userCopying = false;
      this.refresh = !this.refresh;
      this.userDialog = false;
    },

    userEdit(user) {
      this.userEditing = true;
      this.userToAdd = this._.cloneDeep(user);// pre-fill userToAdd with user that is being edited
      this.userDialog = true;
    },

    userRemove: async function(user) {
      for (let group of user.authGroups) {
        try {
          let res = await authGroupAPIService.removeUserFromAuthGroup(this.$route, user.id, group.id)
        } catch (e) {
          console.debug('error', e);
        }
      }
    },

    userRemoveFromGroup(user, groupId) {
      authGroupAPIService.removeUserFromAuthGroup(this.$route, user.id, groupId)
        .then((response) => {// find the group in this.groups and remove user from tooltip
          let group = this.groups.find(o => o.id === groupId);
          group.users = group.users.filter(usr => usr.name!==user.username);
          this.refreshUsersTips = !this.refreshUsersTips;
        })
        .catch((error) => {
          console.debug('error: ', error);
          this.saveError = true;
        });
    },

    userRemoveFromGroups: async function(userToDisable) {
      // remove user from all groups and update user with enabled = false
      let user = userToDisable;
      await this.userRemove(user);
      user.enabled = false;
      this.userConfirmDisableDialog = false;
      await this.userUpdateInDatabase(user);
      // this.usersSetTooltips();
      this.usersGet();
    },

    userSave() {// add user or update user in database,
      if (this.userEditing) {
        this.userUpdateInDatabase(this.userToAdd);
      } else {
        let userToSave = this._.cloneDeep(this.userToAdd);
        userToSave.timeout = `0 years 0 mons 0 days ${userToSave.hours || 0} hours ${userToSave.minutes || 0} mins 0.00 secs`;
        usersAPIService.createUser(userToSave, this.$router)
          .then((response) => {
            this.userNewId = response.id;
            if (this.usersExpanded[this.userToCopy.id]) this.usersExpanded[response.id] = true;
            if (this.userCopying) {// copy authgroups to new user
              for (let group of this.userToCopy.authGroups) {
                authGroupAPIService.addUserToAuthGroup(this.$router, this.userNewId, group.id)
                  .then((response) => {
                    // console.debug('response: ', response);
                  })
                  .catch((error) => {
                    console.debug('error: ', error);
                  });
              }
            }
            this.userDialogRefresh();
            this.saveSuccess = true;
          })
          .catch((error) => {
            console.log('error: ', error);
            this.saveError = true;
          });
      }
    },

    usersGet(initial) {
      let usersToGet = this.allUsers ?  'getUsers' : 'getActiveUsers';
      usersAPIService[usersToGet](this.$router)
        .then((users) => {
          // filter out users with username = null or ''
          this.users = users.filter(user => user.username !== null && user.username !== '');
          for (let user of this.users) {// sort groups within users
            if (user.timeout === null) {
              user.timeout = {};// error if null
            } else {
              user.hours = user.timeout.hours;
              user.minutes = user.timeout.minutes;
            }
            user.authGroups = user.auth_groups;
            this.arraySort(user.authGroups, 'name');// sort authGroups
          }
          this.arraySort(this.users, 'username');// sort users
          if (initial) this.toggleOpen('usersExpanded', true);// initially expand all users
        })
        .catch((error) => {
          console.debug('error: ', error);
        });
    },

    usersHandleChange (newVal) {
      if (!!this.sorting) {
        this.sorting = false;
        return;
      }
      this.userDroppedIn = newVal;
    },

    usersMoveCheckIfToGroupsIn(evt) {// check if move is to groups in users & not duplicate
      this.groupDrag = true;
      this.disallowGroupDrop =  !evt.draggedContext.element.active;
      let duplicate = false;
      for (let group of evt.relatedContext.list) {
        if (group.name === evt.draggedContext.element.name) duplicate = true;
      }
      let toUsers = evt.relatedContext.component.$attrs.group.name === 'groups';
      let moveOk = toUsers && !duplicate;
      this.groupToAddUserTo = moveOk ? evt.draggedContext.element : '';
      return moveOk;
    },

    usersOnDragend: function(evt) {// re-sort groups and update user with dropped(added) group
      this.groupDrag = false;
      if (this.userDroppedIn.enabled === false || this.disallowGroupDrop) return;
      this.sorting = true;
      this.refresh = !this.refresh;
      // sort groups in users to keep alphabetized
      if (!!this.userDroppedIn.authGroups) this.arraySort(this.userDroppedIn.authGroups, 'name');
      // now add user to authgroup using groupToAddUserTo
      authGroupAPIService.addUserToAuthGroup(this.$router, this.userDroppedIn.id, this.groupToAddUserTo.id)
        .then((response) => {
          this.usersGet();
        })
        .catch((error) => {
          console.debug('error: ', error);
        });
    },

    usersSetTooltips() {// update users list for groups tooltips
      for (let group of this.groups) {
        group.users = [];
        for (let user of this.users) {
          if (user.authGroups) {
            for (let auth of user.authGroups) {
              if (auth.name === group.name) {
                group.users.push({name: user.username});
              }
            }
          }
        }
      }
      this.refreshUsersTips = !this.refreshUsersTips;
    },

    userUpdateInDatabase: async function(user) {

      // userUpdateInDatabase(user) {
      let userToSave = this._.cloneDeep(user);
      delete userToSave.password;// don't send password
      userToSave.timeout = `0 years 0 mons 0 days ${userToSave.hours || 0} hours ${userToSave.minutes || 0} mins 0.00 secs`;
      try {
        await usersAPIService.updateUser(user.id, userToSave, this.$route)
        if (this.userEditing) {
          this.saveSuccess = true;
          this.userDialogRefresh();
        }
      } catch (e) {
        if (this.userEditing) this.saveError = true;
      }
    },

    userUpdatePassword() {//confirmPassword is the new password
      usersAPIService.updatePassword(this.userToAdd.id, this.userToAdd.password, this.confirmPassword, this.$route)
        .then((response) => {
          if (!response) {
            this.saveError = true;
          } else {
            this.passwordChangeSuccess = true;
            this.changingPassword = false;
          }
        })
        .catch((error) => {
          this.saveError = true;
        });
    }
  }
};
</script>

<style>
.ghost .v-btn {
  visibility: hidden;
}
.ghost .hide {
  display: none;
}
.closeLabel .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  margin-top: 1px;
  margin-left: -9px;
}
</style>



