<template>
  <div>
    <v-card :key="$_sideMenu" eager style="border-top: 1px solid white !important;"
            class="px-0 pt-0 mb-3 d-flex flex-row justify-space-around"
            color="primary" v-sticky sticky-z-index="9" sticky-offset="$_offset" tile
            large dark block>
      <div>
        <v-btn class="px-1 my-n1" @click="save" :disabled="!valid || !anyFieldsEdited() || readonly"
               :loading="saving" :style="{color: readonly ? 'white !important' : ''}" tile text x-large>
          {{ readonly ? 'View' : 'Save' }} Person</v-btn>
      </div>
    </v-card>
    <v-form ref="fields" v-model="valid">
      <v-row class="ma-1" dense>
        <v-col :class="readonly ? 'unclickable' : ''" cols="12" md="4" :key="card.title" v-for="(card,index) in cards">
          <v-card class="px-2">
            <v-card-title class="pt-1 pb-3"> {{ card.title }} </v-card-title>
            <v-card-text class="mb-n1 pb-0">
              <span :key="field.name + keyRefreshMask" v-for="field in card.val">
                <date-picker-formatted
                    class="my-4 changedfield"
                    :clean-value="personClean[field.val]"
                    clearable
                    :date-formatted-prop="person[field.val]"
                    dense
                    :dirty-check="!loading"
                    @enter="!!person.surname ? save() : ''"
                    :format="dateFormat"
                    :label="field.name"
                    :mask="moment(personClean[field.val], dateFormat, true).isValid() || person[field.val] !== personClean[field.val] ? dateMask : ''"
                    :menu-max-width="'240'"
                    :menu-nudge-left="'259'"
                    :menu-nudge-top="'15'"
                    menu-wrapper
                    no-title
                    offset-y
                    picker-color="secondary"
                    :picker-width="'240'"
                    prepend-inner-icon="mdi-calendar-month"
                    :readonly="readonly"
                    :rules="person[field.val] && personClean[field.val] && person[field.val].replace(/\D/g, '') === personClean[field.val].replace(/\D/g, '') ? [] : [rules.date]"
                    :transition="'scroll-x-reverse-transition'"
                    v-if="dates.includes(field.val)"
                    v-model="person[field.val]"/>
                <smart-input
                    allow-str
                    class="my-4 changedfield"
                    :clean-value="personClean[field.val]"
                    clearable
                    dense
                    :dirty-check="!loading && !readonly"
                    @enter="!!person.surname ? save() : ''"
                    :hint="field.val === 'prefix' ? 'Mr., Ms., Mrs., Dr., etc.'
                      : field.val === 'suffix' ? 'Esq., PhD, MD, CPA, Jr., Sr., etc.' : ''"
                    :items="$_lists.role"
                    :item-text="item =>`${item.value} - ${item.long}`"
                    :item-value="'value'"
                    :label="field.name"
                    :mask="field.val === 'ext' ? '##########' : field.val === 'ssn' ? (readonly ? '***-**-####' : '###-##-####') : '(###) ###-####'"
                    :mask-exists="maskFields.includes(field.val)"
                    :menu-props="{ contentClass: 'compact short' }"
                    :readonly="readonly"
                    @revert="refreshMask()"
                    :rules="!readonly ? field.val === 'surname' ? [rules.required] : field.val === 'ssn' ? [rules.ssn] : [] : []"
                    :style="card.title === 'Phone' ? 'margin-top: 28px !important' : ''"
                    :type="field.val === 'role' ? 'VAutocomplete' : 'VTextField'"
                    v-model="person[field.val]"
                    v-else/>
                    <!-- Fewer 'Phone' fields so add extra margin-top in style prop above so card heights match -->
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense class="mx-1 mt-n2 mb-n6" justify="center">
        <v-col cols="12" sm="12">
          <v-card class="px-2">
            <v-card-text class="pb-1">
              <smart-input
                  class="changedfield"
                  :clean-value="personClean.email"
                  clearable
                  dense
                  :dirty-check="!loading"
                  @enter="!!person.surname ? save() : ''"
                  label="Email"
                  :readonly="readonly"
                  :rules="[rules.email]"
                  v-model="person.email"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <br>
    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='EditPerson'"/>
    <ErrorDialog ref="errorDialog"/>

  </div>
</template>

<script>
import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";
import moment from "moment";

const peopleAPIService = new PeopleAPIService();

export default {
  name: "EditPerson",
  props: {
    id: [String, Number],
    default() {
      return {};
    }
  },
  data() {
    return {
      cards: [
        {title: 'Id', val: [
            {name: 'Prefix',     val: 'prefix'},
            {name: 'Given Names',val: 'given'},
            {name: 'Surname',    val: 'surname'},
            {name: 'Suffix',     val: 'suffix'},
            {name: 'Salutation', val: 'salutation'},
            {name: 'Role',       val: 'role'},
          ]},
        {title: 'Phone', val: [//Fewer 'Phone' fields so add extra margin-top in html style prop so card heights match
            {name: 'Direct', val: 'direct'},
            {name: 'Ext.',   val: 'ext'},
            {name: 'Fax',    val: 'fax'},
            {name: 'Mobile', val: 'mobile'},
            {name: 'Home',   val: 'home'},
          ]},
        {title: 'HR', val: [
            {name: 'Born',     val: 'born'},
            {name: 'Licensed', val: 'licensed'},
            {name: 'Hired',    val: 'hired'},
            {name: 'SSN',      val: 'ssn'},
            {name: 'Hours',    val: 'hours'},
            {name: 'CE',       val: 'ce'},
          ],}
      ],
      dates: ['licensed', 'hired', 'born'],
      dateFormat: 'MM-DD-YYYY',
      dateMask: 'XX-XX-XXXX',
      keyRefreshMask: 0,// inc num to trigger keyed refresh of masked fields after loading/reverting value -phone,etc
      loading: false,
      maskFields: ['ssn', 'direct', 'ext', 'mobile', 'home', 'fax'],// dates not included so they will save with masks
      person: {},
      personClean: {},
      readonly: !this.$_can('person-edit'),
      rules: {
        date: (x) => (!x || this.valDate(x)) || `Must be valid ${this.dateFormat}`,
        email: (x) => !x || /.+@.+\..+/.test(x) || 'Email must be valid',
        required: (x) => !!x || 'This field is required.',
        ssn: (x) => !x || x.length === 11 || 'SSN must be XXX-XX-XXXX',
      },
      saving: false,
      valid: true,
    };
  },

  created() {
    this.getPerson();
  },

  methods: {
    anyFieldsEdited() {// have any of the person fields been edited?
      if (!this.person) return;
      let fieldEdited = false;
      for (let f in this.person) {
        // check each field to see if value != cleanValue (except maskFields or dates--first strip mask from value, then compare)
        // if (this.maskFields.includes(f) || this.dates.includes(f)) {
        if (this.maskFields.includes(f)) {
          let fieldWithAnyMaskRemoved = this.person[f] ? this.person[f].replace(/\D/g, '') : this.person[f];
          if (!this.$_eq(fieldWithAnyMaskRemoved, this.personClean[f])) fieldEdited = true;
        } else {
          if (!this.$_eq(this.person[f], this.personClean[f])) fieldEdited = true;
        }
      }
      return fieldEdited;
    },

    getPerson() {
      this.loading = true;
      peopleAPIService.getPerson(this.id, this.$router)
        .then((person) => {
          this.person = JSON.parse(JSON.stringify(person));// person & personClean need to be set to same value
          this.personClean = JSON.parse(JSON.stringify(person));
          document.title = `${this.person.fid ? 'Firm ' + this.person.fid + ' -' : ''}  ${this.readonly ? 'View' : 'Edit'} Person`;
          this.keyRefreshMask++;// trigger v-mask to re-render in phone fields
          this.loading = false;
          setTimeout(() => { if (this.$refs.fields) this.$refs.fields.validate() }, 100);
        })
        .catch(e => {
          this.loading = false;
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
    },

    refreshMask() {
      setTimeout(()=>{ this.keyRefreshMask++ }, 50);// trigger v-mask to re-render in phone fields
    },

    removeMasks(data) {// remove masks for saving to database
      for (let d of data) {
        for (let f of this.maskFields) if (d[f]) d[f] = d[f].replace(/\D/g, '');
      }
      return data;
    },

    save() {
      this.saving = true;
      let personToSave =  _.cloneDeep(this.person);
      //set all undefined properties to null, or they won't be emptied
      Object.entries(personToSave).forEach(([key, value]) => {if (value === undefined) personToSave[key] = null});
      [personToSave] = this.removeMasks([personToSave]);// removeMasks expects array
      return peopleAPIService.updatePerson(personToSave.plid, personToSave, this.$router)
          .then((data) => {
            this.saving = false;
            this.getPerson();
            this.$store.dispatch('SET_SNACK', {cmpnt: 'EditPerson', open: true, msg: 'Person was saved'});
          })
          .catch(e => {
            this.saving = false;
            this.$refs.errorDialog.showError({
              forDevelopers: e
            })
          });
    },

    valDate(date) {
      let valid = moment(date, this.dateFormat, true).isValid();
      return valid;
    },

  }
};
</script>
<style>
</style>
