<template>
  <div v-if="editing">
  <div class="headline px-12 pt-3 pb-2">Edit Claim</div>
  <v-card :key="$_sideMenu" style="border-top: 1px solid white !important;"
          class="px-0 py-0 my-0 d-flex flex-row justify-space-around"
          color="primary" v-sticky sticky-z-index="9" tile large dark block
          sticky-offset="$_offset">
    <v-tooltip bottom style="z-index: 99">
      <template v-slot:activator="{ on }">
        <div>
          <v-btn class="px-1" x-large tile text v-on="on" @click="zero" :disabled="anyLessThan0ExceptDeduc">Zero</v-btn>
          <information-dialog-icon confirmClass="mr-3 title" confirmColor="secondary darken-1" icon-class="ml-n2"
                                   tclass="headline grey lighten-2 mb-8" title="Zero Out Reserves" >
            Click 'ZERO' to eliminate all remaining reserves for monitor, defense, and damages. This will be disabled if
            any amounts (monitor, defense, or damages) are less than zero.
          </information-dialog-icon>
        </div>
      </template>
      <span>Zero out reserves</span>
    </v-tooltip>
    <v-tooltip bottom style="z-index: 99">
      <template v-slot:activator="{ on }">
        <div>
          <v-btn class="px-1" @click="closePage = false, save()" :disabled="anyLessThanZero" text tile v-on="on"
                 x-large>Save</v-btn>
          <information-dialog-icon confirmClass="title" confirmColor="secondary darken-1" icon-class="ml-n2"
                                   tclass="headline grey lighten-2 mb-8" title="Save Claim">
            Click 'SAVE' to save claim changes. This will be disabled if any amounts (monitor, defense,
            damages, or deductible) are less than zero.
          </information-dialog-icon>
        </div>
      </template>
      <span>Save claim</span>
    </v-tooltip>
  </v-card>
        <v-row dense class="px-5" mx-auto>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-card-title>Policy</v-card-title>
              <v-card-text style="padding-bottom: 48px" class="shortBox">
                <v-autocomplete class="mb-8" :items="policies" label="Policy"
                                item-value="pid" v-model="clm.pid"/>
                <ListViewer   v-model="clm.carrier" listname="carrier" clearable
                              @click:clear="clm.carrier = null"
                              :title="true" :nameonly="true" sorttable="short"
                              listtitle="Carrier" class="mb-8" sort="0"/>
                <ListViewer   v-model="clm.type" nameonly :menuprops="{ maxHeight: 270 }"
                              :title="true" listname="ptype" sorttable="short" clearable
                              listtitle="Type" class="mb-8" sort="0"/>
                <v-text-field v-model="clm.fid" id="edit_fid_text" label="Firm ID" readonly
                              ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-card-title>Counsel</v-card-title>
              <v-card-text style="padding-bottom: 48px">
                <v-autocomplete spellcheck="false" item-text="name1" item-value="cid"
                                label="Claims Handlers" clearable
                                v-model="clm.adj_cid" :items="claimsHandlers"
                                @input="clm.adj_cid === '0' ? '' : getClaimsCounsel(clm.adj_cid)"
                                class="mb-8"
                ></v-autocomplete>
                <v-autocomplete spellcheck="false" hint="Select Claims Handler first"
                                item-value="plid" v-model="clm.adj_plid" persistent-hint
                                item-text="sortName" clearable
                                :items="claimsCounsel" class="mb-8" label="Claims Counsel"
                ></v-autocomplete>
                <v-autocomplete spellcheck="false" item-text="name1" item-value="cid"
                                label="Defense Firm" clearable
                                v-model="clm.def_cid" :items="defenseFirm"
                                @input="getDefenseCounsel(clm.def_cid)"
                                class="mb-8"
                ></v-autocomplete>
                <v-autocomplete spellcheck="false" hint="Select Defense Firm first"
                                item-value="plid" v-model="clm.def_plid" persistent-hint
                                item-text="sortName" clearable
                                :items="defenseCounsel" label="Defense Counsel"
                ></v-autocomplete>
               </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-card-title>Parties</v-card-title>
              <v-card-text style="padding-bottom: 48px">
                <v-text-field v-model="clm.claimant" label="Claimant" class="mb-2">
                </v-text-field>
                <ListViewer   v-model="clm.plt_code" listtitle="Plaintiff Code"
                              :title="true" clearable
                              sorttable="short" listname="plt_code" :nameonly="false" sort="1,2,3,4"/>
                <v-text-field v-model="clm.insdef" label="Insured Defendants"
                              class="mb-2"></v-text-field>
                <ListViewer v-model="clm.def_code" listtitle="Defendant Code" :title="true" clearable
                            sorttable="short" listname="def_code" :nameonly="false" sort="0,1,2,3"
                            style="margin-bottom: 10px"/>
                <v-text-field v-model="clm.othdef" label="Other Defendants"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-card-title>Claim Details</v-card-title>
              <v-card-text  style="padding-bottom: 4px">
                <v-text-field v-model="clm.clm_num" spellcheck="false" class="mb-3"
                              label="Claim Number"></v-text-field>
                <ListViewer v-model="clm.state" :nameonly="true" class="mb-3" clearable
                            sorttable="short" listname="states" listtitle="Claim State"/>
                <ListViewer v-model="clm.err_code" :nameonly="false" listname="err_code"
                            :title="true" sorttable="short" listtitle="Error Type"
                            clearable sort="0,1,2,3,4,5,6" class="mb-3 pb-4"/>
                <div v-if="(clm.firm && clm.firm.state)">
                  <ListViewer v-model="clm.firm.state" hint="Firm state cannot be edited"
                            :nameonly="true" class="mb-3" readonly
                            listname="states" listtitle="Firm State"/>
                </div>
                <div v-else class="my-6 py-6"></div>
                <span><v-checkbox v-model="clm.insuit" hide-details color="accent1" label="In Suit"
                                  class="mt-7 mr-2 mb-8 shrink"></v-checkbox></span>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-card-title>More Details</v-card-title>
              <v-card-text style="padding-bottom: 48px">
                <v-text-field v-model="clm.est_size" spellcheck="false"
                            label="Estate Size" class="mb-8"
                            hint="Numbers only, no punctuation." persistent-hint></v-text-field>
                <ListViewer v-model="clm.aop_code" listname="aop_code"
                            :title="true" clearable
                            class="mb-8" listtitle="Select AOP" :nameonly="false" longonly
                            sort="1,2,3,4,5,6,7,8,9,10"/>
                <v-select v-model="clm.resolved" label="Resolution" clearable
                            class="mb-8" :items="['judgement', 'settlement']"><div id="resolve"></div>
                </v-select>
                <ListViewer v-model="clm.status" :nameonly="true" listname="clmstatus"
                            :title="true" sorttable="short" clearable
                            listtitle="Select a Status" sort="0,1,2,3,4"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card class="pb-9">
              <v-card-title>Claim Dates</v-card-title>
              <v-card-text v-for="date in datesClaim" :key="date.name">
                  <v-menu :close-on-content-click="false"
                          offset-y min-width="290px"
                          transition="scale-transition"
                          v-model="menu[date.name]"
                  ><template v-slot:activator="{ on }">
                      <v-text-field clearable
                                    @click="newDate[date.name] = false"
                                    @click:clear="clm[date.name] = ''"
                                    :label="date.label + ' date'"
                                    readonly
                                    style="margin-bottom: -4px"
                                    v-model="newDate[date.name] === true ? ''
                                      : moment(clm[date.name], 'YYYY-MM-DD').format('DD MMM YYYY')"
                                    v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker @input="menu[date.name] = false"
                                   no-title
                                   v-model="clm[date.name]"
                    ></v-date-picker>
                  </v-menu>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card class="pb-9">
              <v-card-title>Handling Dates</v-card-title>
              <v-card-text v-for="date in datesHandling" :key="date.name">
                <v-menu :close-on-content-click="false"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                        v-model="menu[date.name]"
                ><template v-slot:activator="{ on }">
                    <v-text-field clearable
                                  @click="newDate[date.name] = false"
                                  @click:clear="clm[date.name]= ''"
                                  :label="date.label + ' date'"
                                  readonly
                                  style="margin-bottom: -4px"
                                  v-model="newDate[date.name] === true ? ''
                                    : moment(clm[date.name], 'YYYY-MM-DD').format('DD MMM YYYY')"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker @input="menu[date.name] = false"
                                 no-title
                                 v-model="clm[date.name]"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
            </v-card>
          </v-col>

          <amount-card :clmProp="clm" ref="mon" :key="clmLoaded[0]" :title="'Monitoring'" :fields="[
            {name: 'Monitor Reserve',  value:'res_mon'},
            {name: 'Monitor Incurred', value:'monitor'},
            {name: 'Current Monitor',  value:'cur_mon'},
            {name: 'Remaining Monitor',value:''},
            ]"></amount-card>
          <amount-card :clmProp="clm" ref="def" :key="clmLoaded[1]"  :title="'Defense'" :fields="[
            {name: 'Defense Reserve',  value:'res_def'},
            {name: 'Defense Incurred', value:'defense'},
            {name: 'Current Defense',  value:'cur_def'},
            {name: 'Remaining Defense',value:''},
            ]"></amount-card>
          <amount-card :clmProp="clm" ref="dam" :key="clmLoaded[2]" :title="'Damages'" :fields="[
            {name: 'Damages Reserve',  value:'reserve'},
            {name: 'Damages Incurred', value:'damages'},
            {name: 'Current Damages',  value:'cur_dam'},
            {name: 'Remaining Damages',value:''},
            ]"></amount-card>
          <amount-card :clmProp="clm" :key="clmLoaded[3]" :title="'Deductible'" :fields="[
            {name: 'Deductible',          value:'deductible'},
            {name: 'Paid Deductible',     value:'paid_ded'},
            {name: 'Remaining Deductible',value:''},
            ]"></amount-card>


<!--          <v-col cols="12" sm="6" md="4" lg="3" xl="2">-->
<!--            <v-card height="476">-->
<!--              <v-card-title>Claim Updates</v-card-title>-->
<!--              <v-card-text class="mx-4">-->
<!--                <v-row v-for="update in clm.updates" :key="update.timestamp">-->
<!--                  <v-col cols="6">-->
<!--                    {{ update.username }}-->
<!--                  </v-col>-->
<!--                  <v-col cols="6">-->
<!--                    {{ update.timestamp }}-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-card-text>-->
<!--            </v-card>-->
<!--          </v-col>-->

        </v-row>

     <v-row class="px-4">
      <v-col>
        <v-card>
          <v-card-title class="mt-n1 mb-1">Facts</v-card-title>
          <div :key="resetFactsEditor" class="clm.factsHtml ? mt-n5 : mt-12">
<!--            <ckeditor v-if="clm.factsHtml" type="classic" v-model="clm.facts"></ckeditor>-->
            <v-card v-if="clm.factsHtml" class="ckblock scroll mt-2 pa-4" max-height="200" v-html="htmlFacts"></v-card>
            <v-textarea v-else class="mb-n5 mt-2 mx-4" v-model="clm.facts"></v-textarea>
            <v-row justify="end" class="mr-4">
              <v-btn v-if="clm.factsHtml" text x-large color="secondary darken-2" @click="revertFactsToPlainText">
                Show Plain Text
              </v-btn>
<!--                Revert To Plain Text-->
              <v-btn v-else text x-large color="secondary darken-2" @click="convertFactsToHtml">
                Render HTML
              </v-btn>
<!--                Convert To HTML-->
              <information-dialog-icon icon-class="mb-n5 mt-2 ml-n2 mr-6" title="Rendering Claim Facts HTML">
                Converting claim facts to HTML means that you will be shown how your facts look with
                the HTML rendered, but your facts will not be editable in this mode. To continue
                editing, click "show plain text".
              </information-dialog-icon>
<!--              <information-dialog-icon icon-class="mb-n5 mt-2 ml-n2 mr-6" title="Converting Claim Facts to HTML">-->
<!--                Converting claim facts to HTML means that you will be provided an HTML editor to edit the claim facts as-->
<!--                opposed to a plain text box. This will add HTML tags to the text of your claim facts, and give you more styling options. You-->
<!--                won't be able to see the HTML tags unless you edit the claim facts in in.dominioninsurance.com. If you have already-->
<!--                converted the claim facts to HTML, a "revert to plain text" button will appear, and the system will-->
<!--                attempt to strip and replace any added HTML tags with plain text characters. Note that if you have a table-->
<!--                or other additional non-text elements, there will be no way to remove these and maintain the contents-->
<!--                of the claim facts. Those HTML tags will remain in your claim facts, even after reverting to plain text.-->
<!--              </information-dialog-icon>-->
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

     <v-row class="px-4">
      <v-col>
        <v-card>
          <v-card-title class="mt-n1 mb-1">Note</v-card-title>
          <div :key="resetNoteEditor" class="clm.factsHtml ? mt-n5 : mt-12">
<!--            <ckeditor v-if="clm.noteHtml" type="classic" v-model="clm.note"></ckeditor>-->
            <v-card v-if="clm.noteHtml" class="ckblock scroll mt-2 pa-4" max-height="200" v-html="htmlNote"></v-card>
            <v-textarea v-else class="mb-n5 mt-2 mx-4" v-model="clm.note"></v-textarea>
            <v-row justify="end" class="mr-4">
              <v-btn v-if="clm.noteHtml" text x-large color="secondary darken-2" @click="revertNoteToPlainText">
                Show Plain Text
              </v-btn>
<!--                Revert To Plain Text-->
              <v-btn v-else text x-large color="secondary darken-2" @click="convertNoteToHtml">
                Render HTML
              </v-btn>
<!--                Convert To HTML-->
<!--              <information-dialog-icon icon-class="mb-n5 mt-2 ml-n2 mr-6" title="Converting Claim Note to HTML">-->
              <information-dialog-icon icon-class="mb-n5 mt-2 ml-n2 mr-6" title="Rendering Claim Note HTML">
                Converting a claim note to HTML means that you will be shown how your note looks with
                the HTML rendered, but your note will not be editable in this mode. To continue
                editing, click "show plain text".
              </information-dialog-icon>
<!--              <information-dialog-icon icon-class="mb-n5 mt-2 ml-n2 mr-6" title="Converting Claim Note to HTML">-->
<!--                Converting a note to be an HTML note means that you will be provided an HTML editor to edit the note as-->
<!--                opposed to a plain text box. This will add HTML tags to your note, and give you more styling options. You-->
<!--                won't be able to see the HTML tags unless you edit the note in in.dominioninsurance.com. If you have already-->
<!--                converted the note to be an HTML note, a "revert to plain text" button will appear, and the system will-->
<!--                attempt to strip and replace any added HTML tags with plain text characters. Note that if you have a table-->
<!--                or other additional non-text elements, there will be no way to remove these and maintain the contents-->
<!--                of the note. Those HTML tags will remain in your note, even after reverting to plain text.-->
<!--              </information-dialog-icon>-->
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

        <v-row v-if="clm.files && clm.files.length >= 1">
          <div class="headline px-3 mx-4 py-6">File Links</div>
        </v-row>
        <v-row v-for="file in clm.files" :key="file.loidfid">
          <v-col>
            <a class="mx-4" :href="`${url}/download/${file.loidfid}`">
              {{ file.filename }}
            </a>
          </v-col>
        </v-row>
        <br>
        <br>

    <snack :color="$_snack.color" :msg="$_snack.msg" style="margin-top: -5px" v-model="$_snack.open"
           v-if="$_snack.cmpnt==='EditClaim'"></snack>

    <ErrorDialog ref="errorDialog"/>

  </div>
</template>


<script>
  import AmountCard from "@/components/search/claims/AmountCard";
  import ErrorDialog from "../../ErrorDialog";
  import InformationDialogIcon from "../../InformationDialogIcon";
  import ListViewer from '@/components/ListViewer';
  import moment from 'moment';
  import { ClaimsAPIService } from '@/servicehandlers/ClaimsAPIService';
  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  import { ContactAPIService } from '@/servicehandlers/ContactAPIService';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { ListsAPIService } from "@/servicehandlers/ListsAPIService";
  import {VMoney} from 'v-money'

  const apiService = new CommonAPIService();
  const claimAPIService = new ClaimsAPIService();
  const contact = new ContactAPIService();
  const firmsAPIService = new FirmsAPIService();
  const listApiService = new ListsAPIService();

  export default {
    name: 'EditClaim',
    props: {
      id: [String, Number],
      claim: Object,
      default() {
        return {};
      }
    },
    components: { AmountCard, ErrorDialog, ListViewer, InformationDialogIcon },
    directives: {money: VMoney},
    data: () => ({
      claimsCounsel: [],
      claimsHandlers: [],
      claimToSave: {},
      clm: {},
      clmLoaded: [],
      closePage: false,
      datesClaim: [
        { name: 'engaged',  label: 'Engaged' },
        { name: 'error',    label: 'Error' },
        { name: 'claim',    label: 'Claim' },
        { name: 'report',   label: 'Report' },
      ],
      datesHandling: [
        { name: 'clm_ack',  label: 'Claims Acknowledged' },
        { name: 'first_rep',label: 'First Report' },
        { name: 'last_rep', label: 'Last Report' },
        { name: 'close',    label: 'Close' },
      ],
      defenseFirm: [],
      defenseCounsel: [],
      editing: true,
      errors: [],
      loggedIn: false,
      menu: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 2,
      },
      newDate: [],
      policies: [],
      resetNoteEditor: false,
      resetFactsEditor: false,
      styl: '<style> table {width: auto; border-collapse: collapse; } table, th, td ' +
        '{border: 1px solid black;} td {padding: 1em;} </style>',
      url: process.env.VUE_APP_UW_URL,
      zeroMonitor: 'zeroMonitor',
      zeroDefense: 'zeroDefense',
      zeroDamages: 'zeroDamages',
    }),

    computed: {
      anyLessThanZero() {// loop through all amounts and if any < 0, then set anyLessThanZero=true. Return anyLessThanZero.
        let anyLessThanZero = false;
        let amounts = ['res_mon', 'monitor', 'cur_mon', 'res_def', 'defense', 'cur_def', 'reserve', 'damages',
          'cur_dam', 'deductible', 'paid_ded'];
        for (let amt of amounts) if (!!this.clm[amt] && this.clm[amt].replace(/[\$|,]/g, '') < 0) anyLessThanZero = true;
        return anyLessThanZero;
      },

      anyLessThan0ExceptDeduc() {// loop through all amounts and if any < 0 (except deductible),
        // then set anyLessThan0ExceptDeduc=true. Return anyLessThan0ExceptDeduc.
        let anyLessThan0ExceptDeduc = false;
        let amts = ['res_mon', 'monitor', 'cur_mon', 'res_def', 'defense', 'cur_def','reserve', 'damages','cur_dam'];
        for (let a of amts) if (!!this.clm[a] && this.clm[a].replace(/[\$|,]/g, '') < 0) anyLessThan0ExceptDeduc = true;
        return anyLessThan0ExceptDeduc;
      },

      dates()  { return this.datesClaim.concat(this.datesHandling) },

      // styledNote()  { return this.styl + this.clm.note },
      // styledFacts() { return this.styl + this.clm.facts },

      htmlFacts() {
        return this.clm.facts.replace(/(?:\r\n|\r|\n)/g, '<br>');
      },

      htmlNote() {
        return this.clm.note.replace(/(?:\r\n|\r|\n)/g, '<br>');
      },
    },

    created: async function() {
      this.loginCheck();
        claimAPIService.getSingleClaim(this.id, this.$router)
          .then((clm) => {
            if (clm.insuit === 'yes' || clm.insuit === 'true') {
              clm.insuit = 1;
            } else {
              clm.insuit = 0;
            }
            for (let i = 0; i < 4; i += 1) {this.clmLoaded[i] = i};
            this.clm = clm;
            // this.clm.facts = this.clm.facts ? this.clm.facts.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
            // this.clm.note = this.clm.note ? this.clm.note.replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
            this.clm.factsHtml = false;
            this.clm.noteHtml = false;
            this.getClaimData();
            this.getPolicies();
          })
    },

    methods: {
      convertFactsToHtml() {
        // this.clm.facts = this.clm.facts.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.clm.factsHtml = true;
        this.resetFactsEditor = !this.resetFactsEditor;
      },

      convertNoteToHtml() {
        // this.clm.note = this.clm.note.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.clm.noteHtml = true;
        this.resetNoteEditor = !this.resetNoteEditor;
      },

      dateForPicker(dates) {
        // if date empty set date picker to today, else convert to date picker will accept
        for (let date of dates) {
          if (!this.clm[date.name]) {
            //set date to today for datepicker but not for text-field
            this.newDate[date.name] = true;
            this.clm[date.name] = new Date().toISOString().substr(0, 10);
          } else {
            this.clm[date.name] = moment(this.clm[date.name], 'DD MMM YYYY').format('YYYY-MM-DD');
          }
        }
      },

      dateToSave(dates) {
        for (let date of dates) {
          //first check for null value and allow that to be sent back
          if (this.claimToSave[date.name] === null || this.claimToSave[date.name] === ''
            || this.newDate[date.name] === true) {
            this.claimToSave[date.name] = null;
          } else {
            //if date is valid, check if format is valid for api and if not, convert to correct format
            if (moment(this.claimToSave[date.name]).isValid()) {
              if (!moment(this.claimToSave[date.name], 'DD MMM YYYY', true).isValid()) {
                this.claimToSave[date.name] = moment(this.claimToSave[date.name], 'YYYY-MM-DD').format('DD MMM YYYY');
              }
            } else { //delete the date
              delete this.claimToSave[date.name];
            }
          }
        }
      },

      getClaimData () {
        document.title = this.clm.clm_num || '';
        if (this.clm.facts === null) this.clm.facts = '';// ckeditor has error if field null
        if (this.clm.note === null) this.clm.note = '';// ckeditor has error if field null
        this.clm.updates = [
          {username: 'anthony', timestamp: 'June 25, 2020'},
          {username: 'anthony', timestamp: 'June 25, 2020'},
          {username: 'anthony', timestamp: 'June 25, 2020'}
        ];// TODO remove after api ready

        //get dates in format for picker, use today if empty date
        this.dateForPicker(this.dates);

        listApiService.getList('aop_code',[1,2,3,4,5,6,7,8,9,10],false,true,'ASC','sort', null,
          this.$router)
          .then((data) => {
            // assemble the data into a format we can use
            let newOptions = [];
            for (let i = 0; i < data.length; i += 1) {
              let newItem = {
                'name': data[i].text,
                'value': data[i].value,
              };
              newOptions.push(newItem);
            }
            this.AOPOptions = newOptions.sort();
          })
          .catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
        window.scrollTo(0, top);
        const mytype = 'Adjuster' + '';//get claims handler, counsel
        contact.getByType(mytype, this.$route)
          .then((handlers) => {
            //database has extra whitespace, so trim, so it will look right and sort correctly
            for (let handler of handlers) handler.name1 = handler.name1.trim();
            this.claimsHandlers = handlers.sort((a, b) => {
              const textA = a.name1.toUpperCase();
              const textB = b.name1.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            if (!!this.clm.adj_cid) this.getClaimsCounsel(this.clm.adj_cid);
          })
          .catch((error) => {
            console.debug('error: ', error);
          });

        const type = 'Panel' + '';//get defense Firm, counsel
        contact.getByType(type, this.$route)
          .then((defenseFirm) => {
            //database has extra whitespace, so trim
            for (let defense of defenseFirm) defense.name1 = defense.name1.trim();
            this.defenseFirm = defenseFirm.sort((a, b) => {
              const textA = a.name1.toUpperCase();
              const textB = b.name1.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            if (!!this.clm.def_cid) this.getDefenseCounsel(this.clm.def_cid);
          })
          .catch((e) => {
            this.$refs.errorDialog.showError({
              forDevelopers: e
           })
          });
      },

      getClaimsCounsel(id) {
        this.claimsCounsel = [];
        // find claimsCounsel by id
        for (let handler of this.claimsHandlers) {
          if (handler.cid === id) {
            this.claimsCounsel = handler.people;
            for (let person of this.claimsCounsel) {
              if (person) {
                // person.fullName = person.given + " " + person.surname;
                // if a name is missing or whitespace, then don't insert comma
                person.sortName = (!person.surname || !person.surname.trim() || !person.given || !person.given.trim())
                  ? person.surname.trim() + person.given
                  : person.surname.trim() + ", " + person.given;
              }
            }
          }
        }
        this.claimsCounsel.sort((a, b) => {
          const textA = a.sortName.toUpperCase();
          const textB = b.sortName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },

      getDefenseCounsel(id) {
        this.defenseCounsel = [];
        // find defenseCounsel by id
        for (let defense of this.defenseFirm) {
          if (defense.cid === id) {
            this.defenseCounsel = defense.people;
            for (let person of this.defenseCounsel) {
              if (person) {
                // if a name is missing or whitespace, then don't insert comma
                person.sortName = (!person.surname || !person.surname.trim() || !person.given || !person.given.trim())
                  ? person.surname.trim() + person.given
                  : person.surname.trim() + ", " + person.given;
              }
            }
          }
        }
        this.defenseCounsel.sort((a, b) => {
          const textA = a.sortName.toUpperCase();
          const textB = b.sortName.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },

      getPolicies() {// for Policy field dropdown
        firmsAPIService.getFirmPolicies(this.clm.fid, this.$router).then((policies) => {
          this.policies = [];
          for (let policy of policies) {
            if (['Book','Past','History'].includes(policy.status)) {// only show Book, Past or History policy options
              let type = policy.type ? `${policy.type}-` : '';// prevent '-' from being displayed in dropdown
              policy.text = `${policy.carrier || ''} (${type}${policy.status}) ${policy.expiry || ''}`;
              if (policy.pid === this.clm.pid) this.policy = policy;// pre-populate policy field if match
              this.policies.push(policy);
            }
          }
        })
        .catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      },

      loginCheck() {
        let loggedIn = this.$store.getters.loggedIn;
        this.loggedIn = loggedIn;
        if (!loggedIn) {
          if (this.$cookies.isKey('sessid')) {
            apiService.cookieLogin(this.$router, this.$store, this.errors)
            .then(() => {
              loggedIn = this.$store.getters.loggedIn;
              this.loggedIn = loggedIn;
              if (!loggedIn) {
                this.$router.push({
                  name: 'Login',
                });
              } else {
                this.rightsCheck();
              }
            }).catch((e) => {
              this.$refs.errorDialog.showError({
                errorMessage: "Failed to get rights.",
                forDevelopers: e
              })
            });
          } else {
            this.$router.push({
              name: 'Login',
            });
          }
        } else {
          this.rightsCheck();
        }
      },

      revertFactsToPlainText() {
        this.clm.factsHtml = false;
        // this.clm.facts = this.clm.facts.replace(/<br\s?\/?>|<\s?\/\s?p>/gm, '\n').replace(/<p\s?>/gm, '').replace(/&nbsp;/gm, ' ');
        this.resetFactsEditor = !this.resetFactsEditor;
      },

      revertNoteToPlainText() {
        this.clm.noteHtml = false;
        // this.clm.note = this.clm.note.replace(/<br\s?\/?>|<\s?\/\s?p>/gm, '\n').replace(/<p\s?>/gm, '').replace(/&nbsp;/gm, ' ');
        this.resetNoteEditor = !this.resetNoteEditor;
      },

      rightsCheck() {
        // do I have rights to view this page?
        let seePage = false;
        const rights = this.$store.getters.scopes;
        for (let i = 0; i < rights.length; i += 1) {
          if (rights[i].name === 'claim-edit') {
            seePage = true;
          }
        }

        if (!seePage) {
          localStorage.removeItem('jwtToken');
          this.$store.dispatch('LOGOUT');
          this.$router.push({
            name: 'Login',
          });
        }
      },

      save() {
        this.claimToSave  =  _.cloneDeep(this.clm);
        this.claimToSave.insuit = this.claimToSave.insuit === true ? 'yes' : 'no';
        //set all undefined properties to null, or they won't be emptied & plid depends on cid
        if (!this.claimToSave.adj_cid) this.claimToSave.adj_plid = null;
        if (!this.claimToSave.def_cid) this.claimToSave.def_plid = null;
        Object.entries(this.claimToSave).forEach(([key, value]) => {
          if (value === undefined || value === '') this.claimToSave[key] = null;
        });

        let amounts = ['res_mon', 'monitor', 'cur_mon', 'res_def', 'defense', 'cur_def',
          'reserve', 'damages', 'cur_dam', 'deductible', 'paid_ded'];
        for (let amount of amounts) {//remove dollars and commas before saving claim
          this.claimToSave[amount] = this.claimToSave[amount].replace(/[\$|,]/g, '');
        }
        //convert dates to DD MMM YYYY for backend, delete if invalid
        this.dateToSave(this.dates);
        claimAPIService.updateClaim(this.claimToSave.clid, this.claimToSave, this.$router)
          .then((response) => {
            this.$store.dispatch('SET_SNACK', {cmpnt: 'EditClaim', open: true, msg: 'Claim was saved.'});
          }).catch((e) => {
          this.$refs.errorDialog.showError({
            forDevelopers: e
          })
        });
      },

      zero() {
        this.$refs.mon.zero();
        this.$refs.def.zero();
        this.$refs.dam.zero();
      },
    },

  };

</script>
<style>
</style>
